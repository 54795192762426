.items[data-v-ca893f25] {
  padding: 0 1rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.items-container[data-v-ca893f25] {
  overflow-y: auto;
  margin-right: 0.1875rem;
  max-height: 21rem;
  padding-bottom: 0.5rem;
}
.headersgrid[data-v-ca893f25],
.item[data-v-ca893f25] {
  display: grid;
  grid-template-columns: 3.9375rem 2.25fr 1.2fr 1fr 1.7fr 1.3fr 1.5fr 1.2fr;
  align-items: center;
  grid-gap: 1.5rem;
}
.headersgrid[data-v-ca893f25] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.4375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-right: 0.625rem;
}
.item[data-v-ca893f25] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding-top: 0.3125rem;
  padding-bottom: 0.5rem;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: 0.0625rem solid transparent;
  position: relative;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  cursor: pointer;
}
.dark .item[data-v-ca893f25] {
  background: #3b435e;
}
.item.item[data-v-ca893f25]:hover {
  border-color: #767bfa;
}
.item div[data-v-ca893f25],
.headersgrid div[data-v-ca893f25] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item img[data-v-ca893f25] {
  height: 2.3125rem;
  width: 100%;
}
.bgwhite[data-v-ca893f25] {
  background-color: #fff;
}
.green[data-v-ca893f25],
.Active[data-v-ca893f25] {
  color: #12c519;
}
.red[data-v-ca893f25],
.Expired[data-v-ca893f25] {
  color: #fa7676;
}
.Scheduled[data-v-ca893f25] {
  color: #d0a879;
}
.shoeimg[data-v-ca893f25] {
  width: 3.9375rem;
  object-fit: contain;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.discount-summary[data-v-ca893f25] {
  max-height: 19rem;
}
.discount-summary-header[data-v-ca893f25] {
  margin-top: 1.5rem !important;
}
.discount-summary-header[data-v-ca893f25],
.discount-summary .item[data-v-ca893f25] {
  grid-template-columns: 0.65fr 0.9fr 1.4fr 0.9fr 1fr 0.3fr 0.5fr;
  grid-gap: 1.1875rem;
}
.discount-summary .item[data-v-ca893f25] {
  padding-top: 0.8125rem;
  padding-bottom: 0.875rem;
}
.cap[data-v-ca893f25] {
  text-transform: uppercase;
}
.del-icon[data-v-ca893f25] {
  width: 1.25rem;
  height: 1.25rem !important;
}
.del-items .item[data-v-ca893f25],
.del-headers[data-v-ca893f25] {
  grid-template-columns: 0.65fr 0.9fr 1.4fr 0.9fr 1fr 0.3fr 0.5fr 1rem 1.25rem !important;
}
.t-headers[data-v-ca893f25],
.t-history .item[data-v-ca893f25] {
  grid-template-columns: 3.2rem 1.8fr 1fr 1fr 1fr 1fr 0.75fr;
}
.yellow[data-v-ca893f25] {
  color: #e7a33e;
}
