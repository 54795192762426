.btn[data-v-d529afa8] {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 9.0625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  width: -webkit-max-content;
  width: max-content;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  min-height: 1.75rem;
  cursor: pointer;
  color: #abafd1;
}
.h-40[data-v-d529afa8] {
  min-height: 2.5rem;
}
.pad[data-v-d529afa8] {
  padding: 0 0.9375rem;
  margin-left: auto;
}
.padding-side[data-v-d529afa8] {
  padding: 0 0.2rem;
}
.dark .btn[data-v-d529afa8] {
  background-color: #353c53;
}
.text[data-v-d529afa8] {
  margin-left: 0.625rem;
  font-weight: 400 !important;
}
.mr-14[data-v-d529afa8] {
  margin-right: 0.875rem;
}
.dark .text[data-v-d529afa8] {
  color: #5a5d74;
}
.dark .lightbtn[data-v-d529afa8] {
  background-color: #3b435e !important;
}
.btn[data-v-d529afa8]:active {
  box-shadow: inset 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
}
.disabled[data-v-d529afa8] {
  pointer-events: none;
  cursor: not-allowed !important;
}
.mx-auto[data-v-d529afa8] {
  margin: 0 auto;
}
.dark .lightish[data-v-d529afa8] {
  background-color: #464f6d;
  color: #5a5d74;
}
.px-1[data-v-d529afa8] {
  padding: 0 1rem;
}
.priceUpdate-btn[data-v-d529afa8] {
  min-width: 5.0625rem;
  margin-left: 0.325rem;
}
.priceUpdate-btn span.text[data-v-d529afa8] {
  margin: 0px !important;
  color: #8ff093;
}
.openPanel-btn[data-v-d529afa8] {
  max-height: 1.75rem;
}
.openPanel-btn span.text[data-v-d529afa8] {
  margin: 0px !important;
}
.ask-btn span.text[data-v-d529afa8] {
  margin: 0px !important;
  color: #767bfa;
}
.ask-btn.asked span.text[data-v-d529afa8] {
  margin: 0px !important;
  color: #fa7676 !important;
}
.ask-btn[data-v-d529afa8] {
  min-width: 4.3rem !important;
}
.share-link-btn[data-v-d529afa8] {
  min-width: 8.3rem !important;
}
.qr-btn[data-v-d529afa8] {
  min-width: 3rem;
  padding-right: 0.5rem;
}
.conf-cashout[data-v-d529afa8] {
  min-width: 11.875rem !important;
}
.cashout[data-v-d529afa8] {
  box-shadow: 0px 0px 2px rgb(0 0 0 / 35%);
  border-radius: 5px;
  padding: 0.625rem;
}
.cashout span.text[data-v-d529afa8] {
  margin: 0px !important;
  color: #12c519;
}

/* .asked {
  box-shadow: inset 0rem 0rem 0.5625rem rgb(0 0 0 / 15%) !important;
} */
