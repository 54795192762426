.label[data-v-9ef6cc72] {
  font-weight: 600;
  font-family: "Poppins";
  line-height: 1.125rem;
}
.msg[data-v-9ef6cc72] {
  font-size: 0.875rem;
  font-family: "Poppins";
  line-height: 1.125rem;
  margin-top: 0.125rem;
}
