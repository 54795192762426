.inventory-container[data-v-8387de67] {
 background: #f6f7ff;
 box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
 border-radius: 0.9375rem;
 padding: 0.8125rem 0;
}
.dark .inventory-container[data-v-8387de67] {
 background: #353c53;
}
.headersection[data-v-8387de67] {
 position: relative;
 padding: 0 1rem;
}
.headersection[data-v-8387de67]::before {
 position: absolute;
 content: "";
 height: 0.0625rem;
 width: 7.3125rem;
 bottom: -0.25rem;
 left: 1rem;
 background-image: url("../../assets/dash.png");
 background-size: contain;
 background-repeat: no-repeat;
}
.dark .headersection[data-v-8387de67]::before {
 background-image: url("../../assets/dashdark.png");
}
.purple[data-v-8387de67] {
 font-weight: 500;
 color: #767bfa;
}
.query[data-v-8387de67]:not(:last-child) {
 margin-right: 2.5rem;
}
.query[data-v-8387de67] {
 font-size: 0.9375rem;
 line-height: 1.375rem;
}
.smtext[data-v-8387de67] {
 font-weight: 600;
 font-size: 0.9375rem;
 line-height: 1.375rem;
 padding-left: 0.25rem;
}
.queryvalue[data-v-8387de67] {
 font-weight: 300;

 margin-left: 0.625rem;
}
.green[data-v-8387de67] {
 color: #12c519;
}
