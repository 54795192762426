.info-div[data-v-5d9c5ed8] {
  display: flex;
  align-items: center;
  height: 3.1875rem;
  width: 100%;
  padding: 1.375rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  background: #f6f7ff;
  border-radius: 0.4375rem;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
}
.dark .info-div[data-v-5d9c5ed8] {
  background: #3b435e;
  color: #5a5d74;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .whitetext[data-v-5d9c5ed8] {
  color: #f6f7ff;
}
.settings[data-v-5d9c5ed8] {
  padding: 0 0.9375rem 0 1.375rem;
}
