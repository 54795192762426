.search-btn[data-v-e405c922] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  height: 1.75rem;
  padding: 0 0.625rem 0 0.75rem;
}
.h-40[data-v-e405c922] {
  height: 2.5rem;
}
.dark .search-btn[data-v-e405c922] {
  background: #353c53;
  box-shadow: 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
}
svg[data-v-e405c922] {
  cursor: pointer;
}
input[data-v-e405c922] {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-right: 0.3125rem;
}
.dark input[data-v-e405c922] {
  color: #f6f7ff;
}
.search-btn input[data-v-e405c922]::-webkit-input-placeholder {
  color: #abafd1;
}
.search-btn input[data-v-e405c922]::placeholder {
  color: #abafd1;
}
.dark .search-btn input[data-v-e405c922]::-webkit-input-placeholder {
  color: #5a5d74 !important;
}
.dark .search-btn input[data-v-e405c922]::placeholder {
  color: #5a5d74 !important;
}
