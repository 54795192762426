.items[data-v-e3e75ef8] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23.6875rem, 1fr));
  grid-gap: 2rem;
}
.item[data-v-e3e75ef8] {
  min-height: 24.5625rem;
}
.items-container[data-v-e3e75ef8] {
  padding-top: 0.5rem;
  max-height: 85vh;
  overflow-x: auto;
  padding-bottom: 2rem;
}
.threegrid[data-v-e3e75ef8] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 19.5625rem 27.5rem;
  grid-gap: 1.6rem;
}
.admingrid.threegrid[data-v-e3e75ef8] {
  grid-gap: 1rem;
}
.box[data-v-e3e75ef8] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.span-2[data-v-e3e75ef8] {
  grid-column: span 2;
}
.twogrid[data-v-e3e75ef8] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.left[data-v-e3e75ef8] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.8125rem;
  grid-template-rows: repeat(3, 22.7625rem);
  padding-right: 1rem;
}
.left.admin[data-v-e3e75ef8] {
  grid-template-rows: repeat(3, 21.5625rem) !important;
}
.leftcontainer[data-v-e3e75ef8] {
  max-height: min(87vh, 61rem);
  overflow-y: auto;
  grid-row: 1/4;
}
.right[data-v-e3e75ef8] {
  grid-row: 1/3;
  display: grid;
  grid-gap: 1.8125rem;
  grid-template-rows: 19.5625rem;
}
.transaction[data-v-e3e75ef8] {
  grid-row: 1/3;
}
.pt-10[data-v-e3e75ef8] {
  padding-top: 0.625rem;
}
.pb-10[data-v-e3e75ef8] {
  padding-bottom: 0.625rem;
}
.mb-0[data-v-e3e75ef8] {
  margin-bottom: 0 !important;
}
.mb-30[data-v-e3e75ef8] {
  margin-bottom: 1.875rem !important;
}
.col-span[data-v-e3e75ef8] {
  grid-row: 1/3;
}
.overflow[data-v-e3e75ef8] {
  max-height: 25.75rem;
  overflow-y: auto;
  margin-right: 1rem;
}
.jc-fe[data-v-e3e75ef8] {
  justify-content: flex-end;
}
.graph-download[data-v-e3e75ef8] {
  cursor: pointer;
  height: 1.375rem;
}
.store-info.threegrid[data-v-e3e75ef8] {
  grid-template-rows: 19.5625rem 26.25rem;
}
