.tooltip[data-v-87907b5e] {
  border-radius: 0.25rem;
  background: #fff;
  padding: 1rem;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(255, 255, 255, 0.25);
  text-align: center;
  color: #26313c;
}
.dark .tooltip[data-v-87907b5e] {
  background-color: #26313c;
  color: #fff;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(0, 0, 0, 0.25);
}
