.headers-grid[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-22672f32] {
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
}
.item-grid[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-22672f32] {
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
}
.dark .item-grid[data-v-22672f32] {
  background: #3b435e;
}
.item-grid[data-v-22672f32]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-22672f32],
.headers-grid div[data-v-22672f32] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-22672f32] {
  cursor: pointer;
}
.green[data-v-22672f32] {
  color: #12c519;
}
.date[data-v-22672f32] {
  white-space: break-spaces !important;
  text-align: right !important;
  color: #767bfa;
}
.items-container[data-v-22672f32] {
  /* max-height: 41.15rem; */
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-22672f32] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
  height: 78vh;
}
.capitalize[data-v-22672f32] {
  text-transform: capitalize;
}
.red[data-v-22672f32] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-22672f32] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-22672f32] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-22672f32] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-22672f32] {
  overflow: visible !important;
}
.purple[data-v-22672f32] {
  color: #767bfa;
}
.headers-grid-listed[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid-listed.listed[data-v-22672f32] {
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
}
.item-grid-listed[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid-listed.listed[data-v-22672f32] {
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
}
.shareBtnWrap[data-v-22672f32] {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3.6rem;
}
.ticket-container[data-v-22672f32] {
  /* max-height: 46rem; */
}
.ticket-collapse-parent-grid[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2rem repeat(5, 1fr);
  align-items: center;
  padding: 0 1rem;
  grid-gap: 0 0.5rem;
  cursor: pointer;
}
.ticket-collapse-child-grid[data-v-22672f32],
.ticket-collapse-child-values-grid[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2rem repeat(6, 1.3fr) 2.4fr 1fr auto;
  grid-gap: 0 0.5rem;
  padding: 1rem;
  padding-top: 0.625rem;
  padding-bottom: 0;
  align-items: center;
}
.ticket-collapse-child-grid[data-v-22672f32] {
  grid-template-columns: 2rem repeat(6, 1.3fr) 2.4fr 1fr 0.7fr;
}
.purchaseId[data-v-22672f32] {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
  overflow: hidden;
  margin: auto;
}
.ticket-collapse-child-grid[data-v-22672f32] {
  padding: 1rem;
  padding-top: 2px;
  padding-bottom: 2px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  margin-top: 0.5rem;
}
.ticket-collapse-child-grid div[data-v-22672f32] {
  display: flex;
  align-items: center;
  position: relative;
}
.ticket-collapse-child-grid div span[data-v-22672f32] {
  display: flex;
  margin-left: 0.425rem;
}
.ticket-collapse-child-grid div span img[data-v-22672f32] {
  width: 1.185rem;
}
.ticket-collapse-child-values-grid[data-v-22672f32] {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 0.5rem;
}
.dark .ticket-collapse-child-grid[data-v-22672f32] {
  border-top: 1px solid;
}
.ticket-collapse[data-v-22672f32] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  padding: 1rem 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  color: #767475;
}
.dark .ticket-collapse[data-v-22672f32] {
  background: #3b435e;
  color: #f6f7ff;
}
.dark .ticket-collapse-child-grid[data-v-22672f32],
.dark .ticket-collapse-child-values-grid[data-v-22672f32] {
  border-color: #5a5d74;
}
.bold[data-v-22672f32] {
  font-weight: 500;
  color: #181818;
}
.dark .bold[data-v-22672f32] {
  color: #e2e2e2;
}
.input-container[data-v-22672f32] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.publish-checkbox-container[data-v-22672f32] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.input[data-v-22672f32] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.08) inset;
  border-radius: 0.4375rem;
  width: -webkit-max-content;
  width: max-content;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input input[data-v-22672f32] {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  width: 3.125rem;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #767475;
}
.dark .input[data-v-22672f32] {
  background: #353c53;
}
.dark .input input[data-v-22672f32] {
  color: #f6f7ff;
}
