@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

body {
  overflow-x: auto;
  font-family: "Poppins", sans-serif;
}

.App {
  background-color: #f6f7ff;
  color: #323a52;
  overflow-x: auto;
}

.App.dark {
  background-color: #323a52;
  color: #f6f7ff;
}

.main-container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: minmax(12.875rem, -webkit-min-content) 1fr;
  grid-template-columns: minmax(12.875rem, min-content) 1fr;
}

a {
  text-decoration: none;
  font-family: inherit;
}

.page {
  min-width: 1200px;
  padding-right: 1.0625rem;
}

.px {
  padding-left: 3.125rem !important;
  padding-right: 0.9375rem !important;
}

.px-24 {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.pl {
  padding-left: 3.125rem !important;
}

.pr {
  padding-right: 0.9375rem !important;
}

.jc-fe {
  justify-content: flex-end;
}

.pr-24 {
  padding-right: 1.5rem !important;
}

input {
  font-family: inherit;
  outline: none;
}
.clickable {
  cursor: pointer;
}

.css-3cjm13-MuiFormControl-root {
  margin: 0rem !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiSelect-select {
  font-family: "Poppins" !important;
  padding: 0.875rem 1.375rem !important;
}

.smselect .MuiSelect-select {
  padding: 0.1875rem 0.75rem !important;
  padding-right: 0 !important;
}

.modal .MuiBackdrop-root.css-sox5kk-MuiBackdrop-root {
  background: rgba(67, 53, 97, 0.22);
  -webkit-backdrop-filter: blur(0.375rem);
          backdrop-filter: blur(0.375rem);
}

.dark.modal .MuiBackdrop-root.css-sox5kk-MuiBackdrop-root {
  background: rgba(24, 22, 37, 0.35);
}

.mt-10 {
  margin-top: 0.625rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.ml-10 {
  margin-left: 0.625rem;
}

.mr-14 {
  margin-right: 0.875rem;
  max-width: 9.0625rem;
  width: 100%;
}

.center {
  text-align: center;
  justify-content: center;
}

.MuiSelect-select[aria-expanded="true"] > img {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

/* width */
::-webkit-scrollbar {
  width: 0.1875rem;
  border-radius: 0.1875rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #abafd1;
  cursor: pointer;
  border-radius: 0.1875rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #767bfa;
  cursor: pointer;
  border-radius: 0.1875rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4d53fd;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.add-btns {
  display: flex;
  margin-bottom: 0.625rem;
}

.flex {
  display: flex;
}

.jc-sp {
  justify-content: space-between;
}

.jc-ce {
  justify-content: center;
}

.jc-e {
  justify-content: end;
}

.al-ce {
  align-items: center;
}

.col {
  flex-direction: column;
}

.ml-5 {
  margin-left: 0.3125rem;
}

.red {
  color: #fa7676 !important;
}

.green {
  color: #12c519;
}

.purple {
  color: #767bfa;
}

.orange {
  color: #d0a879;
}

.blue {
  color: #78aad8;
}

.MuiOutlinedInput-notchedOutline,
.css-igs3ac {
  border: 0rem !important
  ;
}

.Mui-focusVisible {
  background-color: transparent !important;
}

.Mui-selected {
  background-color: rgba(118, 123, 250, 0.2) !important;
}

.sneakers {
  background-color: #7983d0; /* blue */
}

.streetwear {
  background-color: #79c1d0; /* green */
}

.collectibles {
  background-color: #d07979; /* brown */
}

.arts {
  background-color: #d079c8; /* pink */
}

.electronics {
  background-color: #d0a879; /* orange */
}

.watches {
  background-color: #aad079; /* lightgreen */
}

@media (max-width: 1560px) {
  :root {
    font-size: 12.5px;
  }
}

@media (max-width: 1750px) {
  :root {
    font-size: 13.5px;
  }
}

.css-a2af34-MuiTable-root {
  min-width: none !important;
  width: 100%;
}

.recharts-wrapper {
  margin-left: -0.75rem;
}

.price-graph .recharts-wrapper {
  margin-left: -1.25rem;
}

.swiper-button-next {
  background-image: url(/static/media/Right.6c5bb572.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: translateX(0.375rem);
  -webkit-transform: translateX(0.375rem);
  -moz-transform: translateX(0.375rem);
  -ms-transform: translateX(0.375rem);
  -o-transform: translateX(0.375rem);
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url(/static/media/Left.a2f5000d.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: translateX(-0.375rem);
  -webkit-transform: translateX(-0.375rem);
  -moz-transform: translateX(-0.375rem);
  -ms-transform: translateX(-0.375rem);
  -o-transform: translateX(-0.375rem);
}

.swiper-button-prev::after {
  display: none;
}

.qrprint {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrprint1 {
  position: absolute;
  top: 50%;
  width: 350px;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.qrprint2 {
  width: 350px;
  margin: auto;
}

.print2 {
  display: block;
  height: -webkit-max-content;
  height: max-content;
  margin: auto;
  align-items: center;
}

.print3 {
  display: block;
  height: -webkit-max-content;
  height: max-content;
  margin: auto;
  align-items: center;
}

.cap {
  text-transform: capitalize;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.al-fe {
  align-items: flex-end !important;
}

.lockedimg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
  object-fit: cover;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #323a52 !important;
}

.dark
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #5a5d74 !important;
}

.dark .menuitem {
  color: #f6f7ff !important;
}

.dark.modal .btn {
  background-color: #3b435e !important;
}

.css-2lkrky-MuiPaper-root-MuiTableContainer-root {
  overflow-x: visible !important;
}

.css-11c9tec-MuiTableCell-root {
  z-index: 0 !important;
}

.sidebar-container[data-v-49069e96] {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sidebar[data-v-49069e96] {
  background-color: #323a52;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 1.875rem;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
}
.dark .sidebar[data-v-49069e96] {
  background-color: #262c41;
}
.sidebar a[data-v-49069e96],
.sidebar div[data-v-49069e96] {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding: 0 1.375rem;
  color: #abafd1;
  white-space: nowrap;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}
.sidebar a[data-v-49069e96]:hover,
.sidebar div[data-v-49069e96]:hover {
  color: #ffffff;
}
.sidebar a[data-v-49069e96],
.sidebar div[data-v-49069e96] {
  margin-top: 3.125rem;
}
.sidebar svg[data-v-49069e96] {
  margin-right: 1.25rem;
}
.logo[data-v-49069e96] {
  padding-left: 1.375rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.logo img[data-v-49069e96] {
  width: 9.0625rem;
}
.logo span[data-v-49069e96] {
  color: #ffffff;
  font-size: 1.25rem;
  padding-top: 1.125rem;
}
.sidebar .active-link[data-v-49069e96] {
  color: #ffffff;
}
.sidebar .active-link[data-v-49069e96]::after {
  position: absolute;
  content: "";
  right: 0rem;
  width: 0.75rem;
  height: 1rem;
  background-image: url(/static/media/polygon.751adddb.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.dark .sidebar .active-link[data-v-49069e96]::after {
  background-image: url(/static/media/polygon1.c92ca53f.svg);
}
.active-link .active-white[data-v-49069e96] {
  fill: #fff;
}

.header[data-v-0ad5476b] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-0ad5476b] {
  cursor: pointer;
}
.input[data-v-0ad5476b] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-0ad5476b] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-0ad5476b] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-0ad5476b] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-0ad5476b] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-0ad5476b] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-0ad5476b] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-0ad5476b] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-0ad5476b] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-0ad5476b] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-0ad5476b] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-0ad5476b] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-0ad5476b] {
  font-size: 1.125rem;
}
.readOnly[data-v-0ad5476b] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-0ad5476b] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-0ad5476b] {
  grid-column: span 2;
}
.breakup[data-v-0ad5476b] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-0ad5476b] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-0ad5476b] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-0ad5476b] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-0ad5476b] {
  justify-content: space-evenly;
}
.jc-sb[data-v-0ad5476b] {
  justify-content: space-between;
}
.btns[data-v-0ad5476b] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-0ad5476b] {
  padding: 0 1.25rem;
}
.pt-10[data-v-0ad5476b] {
  padding-top: 0.625rem;
}
.table-container[data-v-0ad5476b] {
  margin: 0 0.625rem;
}
.span-2[data-v-0ad5476b] {
  grid-column: 2/4;
}
.green[data-v-0ad5476b] {
  color: #12c519;
}
.mb-20[data-v-0ad5476b] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-0ad5476b] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-0ad5476b] {
  padding: 0 1.25rem;
}
.lg-header[data-v-0ad5476b] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-0ad5476b] {
  cursor: pointer;
}
.search-input-cm[data-v-0ad5476b] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-0ad5476b] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-0ad5476b],
.sm-item-grid[data-v-0ad5476b] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-0ad5476b] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-0ad5476b] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-0ad5476b] {
  background: #3b435e;
}
.sm-items[data-v-0ad5476b] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-0ad5476b] {
  background: #3b435e;
}
.col-grid[data-v-0ad5476b] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-0ad5476b] {
  font-weight: 500;
}
.list-item[data-v-0ad5476b] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-0ad5476b] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-0ad5476b] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-0ad5476b] {
  background: #3b435e;
}
.btn-container[data-v-0ad5476b] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-0ad5476b] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-0ad5476b] {
  background: #383f57;
}
.addTicketModal .header[data-v-0ad5476b] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-0ad5476b] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-0ad5476b] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-0ad5476b] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-0ad5476b] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-0ad5476b] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-0ad5476b] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-0ad5476b] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-0ad5476b] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-0ad5476b],
.add-ticket-details-grid .values-grid[data-v-0ad5476b] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-0ad5476b] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-0ad5476b] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-0ad5476b] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-0ad5476b] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-0ad5476b] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-0ad5476b] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-0ad5476b] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-0ad5476b] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-0ad5476b] {
  width: 100%;
}
.mapImg path.clickable[data-v-0ad5476b] {
  cursor: pointer;
}

.btn[data-v-d529afa8] {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 9.0625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  width: -webkit-max-content;
  width: max-content;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  min-height: 1.75rem;
  cursor: pointer;
  color: #abafd1;
}
.h-40[data-v-d529afa8] {
  min-height: 2.5rem;
}
.pad[data-v-d529afa8] {
  padding: 0 0.9375rem;
  margin-left: auto;
}
.padding-side[data-v-d529afa8] {
  padding: 0 0.2rem;
}
.dark .btn[data-v-d529afa8] {
  background-color: #353c53;
}
.text[data-v-d529afa8] {
  margin-left: 0.625rem;
  font-weight: 400 !important;
}
.mr-14[data-v-d529afa8] {
  margin-right: 0.875rem;
}
.dark .text[data-v-d529afa8] {
  color: #5a5d74;
}
.dark .lightbtn[data-v-d529afa8] {
  background-color: #3b435e !important;
}
.btn[data-v-d529afa8]:active {
  box-shadow: inset 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
}
.disabled[data-v-d529afa8] {
  pointer-events: none;
  cursor: not-allowed !important;
}
.mx-auto[data-v-d529afa8] {
  margin: 0 auto;
}
.dark .lightish[data-v-d529afa8] {
  background-color: #464f6d;
  color: #5a5d74;
}
.px-1[data-v-d529afa8] {
  padding: 0 1rem;
}
.priceUpdate-btn[data-v-d529afa8] {
  min-width: 5.0625rem;
  margin-left: 0.325rem;
}
.priceUpdate-btn span.text[data-v-d529afa8] {
  margin: 0px !important;
  color: #8ff093;
}
.openPanel-btn[data-v-d529afa8] {
  max-height: 1.75rem;
}
.openPanel-btn span.text[data-v-d529afa8] {
  margin: 0px !important;
}
.ask-btn span.text[data-v-d529afa8] {
  margin: 0px !important;
  color: #767bfa;
}
.ask-btn.asked span.text[data-v-d529afa8] {
  margin: 0px !important;
  color: #fa7676 !important;
}
.ask-btn[data-v-d529afa8] {
  min-width: 4.3rem !important;
}
.share-link-btn[data-v-d529afa8] {
  min-width: 8.3rem !important;
}
.qr-btn[data-v-d529afa8] {
  min-width: 3rem;
  padding-right: 0.5rem;
}
.conf-cashout[data-v-d529afa8] {
  min-width: 11.875rem !important;
}
.cashout[data-v-d529afa8] {
  box-shadow: 0px 0px 2px rgb(0 0 0 / 35%);
  border-radius: 5px;
  padding: 0.625rem;
}
.cashout span.text[data-v-d529afa8] {
  margin: 0px !important;
  color: #12c519;
}

/* .asked {
  box-shadow: inset 0rem 0rem 0.5625rem rgb(0 0 0 / 15%) !important;
} */

.input-field[data-v-45561ef2] {
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .input-field[data-v-45561ef2] {
  background: #3b435e;
  color: #f6f7ff;
}
.shadow-offset[data-v-45561ef2] {
  box-shadow: 0rem 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}
.input-field[data-v-45561ef2]::-webkit-input-placeholder {
  font-family: inherit;
  font-weight: 300 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  color: #abafd1;
  opacity: 1 !important;
}
.input-field[data-v-45561ef2]::placeholder {
  font-family: inherit;
  font-weight: 300 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  color: #abafd1;
  opacity: 1 !important;
}
.dark .input-field[data-v-45561ef2]::-webkit-input-placeholder {
  color: #5a5d74;
}
.dark .input-field[data-v-45561ef2]::placeholder {
  color: #5a5d74;
}
.suffix[data-v-45561ef2] {
  color: #767bfa;
}
.sm[data-v-45561ef2] {
  max-width: 2.8125rem;
  padding: 0;
  width: 100%;
  height: 2.125rem;
  text-align: center;
}
.smtext[data-v-45561ef2] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding: 0.5625rem 0.75rem !important;
}
.small[data-v-45561ef2] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 9.0625rem;
  width: 100%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgb(0 0 0 / 15%);
  border-radius: 0.3125rem;
  height: 1.75rem;
  padding: 0 0.625rem 0 0.75rem;
}
.dark .small[data-v-45561ef2] {
  background: #353c53;
}
.large[data-v-45561ef2] {
  height: 5.0625rem;
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.large[data-v-45561ef2]::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.large[data-v-45561ef2]::placeholder {
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.prefix-input[data-v-45561ef2] {
  padding: 0 !important;
  width: 100%;
  box-shadow: none;
  height: 100%;
}
.prefix[data-v-45561ef2] {
  font-size: 0.75rem;
  white-space: nowrap;
  margin-right: 0.125rem;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dark .sm[data-v-45561ef2],
.dark .lightinput[data-v-45561ef2] {
  background-color: #464f6d !important;
}
.ask-input-wrap[data-v-45561ef2] {
  border-radius: 0.4375rem;
  /* padding: 0.875rem 1.375rem; */
  border: 1px solid rgba(50, 58, 82, 0.35);
  width: 100%;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.ask-input-currency[data-v-45561ef2] {
  font-size: 0.9375rem;
  margin-right: 0.125rem;
  font-weight: 500;
  border-right: 1px solid rgba(50, 58, 82, 0.35);
  padding: 0.625rem 1rem;
}
.dark .ask-input-wrap[data-v-45561ef2],
.dark .ask-input-currency[data-v-45561ef2] {
  border-color: #f6f7ff59;
  color: #f6f7ff;
}
.ask-input[data-v-45561ef2] {
  padding-left: 0.625rem;
  font-family: inherit;
  font-weight: 500 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  background-color: inherit;
  border: none;
  width: 100%;
}
.dark .ask-input[data-v-45561ef2] {
  color: #f6f7ff;
}
.discount[data-v-45561ef2] {
  max-width: 9.5125rem;
  padding: 0;
  width: 100%;
  height: 1.825rem;
  text-align: center;
}

.loader[data-v-5ba0269e] {
  display: flex;
  align-items: center;
}
.loader img[data-v-5ba0269e] {
  width: 1.25rem;
  height: 1.25rem;
}
.loading[data-v-5ba0269e] {
  animation: loading-data-v-5ba0269e linear 0.2s infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation: loading-data-v-5ba0269e linear 0.2s infinite;
}
@-webkit-keyframes loading-data-v-5ba0269e {
0% {
}
100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
}
@keyframes loading-data-v-5ba0269e {
0% {
}
100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
}

.formControl[data-v-103e5314] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  margin: 0px !important;
}
.dark .formControl[data-v-103e5314] {
  background: #3b435e;
}
.menuitem[data-v-103e5314] {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
  justify-content: center;
  color: #abafd1;
}
.dark .menuitem[data-v-103e5314] {
  color: #f6f7ff;
}
.menuitem[data-v-103e5314]:hover {
  background-color: rgba(118, 123, 250, 0.15) !important;
}
.menuitem.focusVisible[data-v-103e5314] {
  background-color: rgba(118, 123, 250, 0.15) !important;
}

.label[data-v-9ef6cc72] {
  font-weight: 600;
  font-family: "Poppins";
  line-height: 1.125rem;
}
.msg[data-v-9ef6cc72] {
  font-size: 0.875rem;
  font-family: "Poppins";
  line-height: 1.125rem;
  margin-top: 0.125rem;
}

.inventory-container[data-v-7354fa64] {
 background: #f6f7ff;
 box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
 border-radius: 0.9375rem;
 padding: 0.8125rem 0;
}
.dark .inventory-container[data-v-7354fa64] {
 background: #353c53;
}
.headersection[data-v-7354fa64] {
 position: relative;
 padding: 0 1rem;
}
.headersection[data-v-7354fa64]::before {
 position: absolute;
 content: "";
 height: 0.0625rem;
 width: 7.3125rem;
 bottom: -0.25rem;
 left: 1rem;
 background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
 background-size: contain;
 background-repeat: no-repeat;
}
.dark .headersection[data-v-7354fa64]::before {
 background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.purple[data-v-7354fa64] {
 font-weight: 500;
 color: #767bfa;
}
.query[data-v-7354fa64]:not(:last-child) {
 margin-right: 2.5rem;
}
.query[data-v-7354fa64] {
 font-size: 0.9375rem;
 line-height: 1.375rem;
}
.smtext[data-v-7354fa64] {
 font-weight: 600;
 font-size: 0.9375rem;
 line-height: 1.375rem;
 padding-left: 0.25rem;
}
.queryvalue[data-v-7354fa64] {
 font-weight: 300;

 margin-left: 0.625rem;
}
.green[data-v-7354fa64] {
 color: #12c519;
}

.headers-grid[data-v-0cc70166] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-0cc70166] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.item-grid[data-v-0cc70166] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  height: 3.125rem;
  align-items: center;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-0cc70166] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.dark .item-grid[data-v-0cc70166] {
  background: #3b435e;
}
.item-grid[data-v-0cc70166]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-0cc70166],
.headers-grid div[data-v-0cc70166] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-0cc70166] {
  cursor: pointer;
}
.green[data-v-0cc70166] {
  color: #12c519;
}
.items-container[data-v-0cc70166] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-0cc70166] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-0cc70166] {
  text-transform: capitalize;
}
.red[data-v-0cc70166] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-0cc70166] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-0cc70166] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-0cc70166] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-0cc70166] {
  overflow: visible !important;
}
.purple[data-v-0cc70166] {
  color: #767BFA;
}
.bold[data-v-096c3ece] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.container[data-v-096c3ece] {
  padding: 0 1.25rem;
}
.center[data-v-096c3ece] {
  text-align: center;
  margin-top: 3.375rem;
  margin-bottom: 5rem;
}
.twogrid[data-v-096c3ece] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
}
.btn[data-v-096c3ece] {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding: 0.875rem 0;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.dark .btn[data-v-096c3ece] {
  background: #3b435e;
}
.btn[data-v-096c3ece]:hover {
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.2);
}
.green[data-v-096c3ece] {
  color: #12c519;
}

.header[data-v-3f8b0438] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-3f8b0438] {
  cursor: pointer;
}
.input[data-v-3f8b0438] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-3f8b0438] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-3f8b0438] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-3f8b0438] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-3f8b0438] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-3f8b0438] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-3f8b0438] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-3f8b0438] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-3f8b0438] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-3f8b0438] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-3f8b0438] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-3f8b0438] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-3f8b0438] {
  font-size: 1.125rem;
}
.readOnly[data-v-3f8b0438] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-3f8b0438] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-3f8b0438] {
  grid-column: span 2;
}
.breakup[data-v-3f8b0438] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-3f8b0438] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-3f8b0438] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-3f8b0438] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-3f8b0438] {
  justify-content: space-evenly;
}
.jc-sb[data-v-3f8b0438] {
  justify-content: space-between;
}
.btns[data-v-3f8b0438] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-3f8b0438] {
  padding: 0 1.25rem;
}
.pt-10[data-v-3f8b0438] {
  padding-top: 0.625rem;
}
.table-container[data-v-3f8b0438] {
  margin: 0 0.625rem;
}
.span-2[data-v-3f8b0438] {
  grid-column: 2/4;
}
.green[data-v-3f8b0438] {
  color: #12c519;
}
.mb-20[data-v-3f8b0438] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-3f8b0438] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-3f8b0438] {
  padding: 0 1.25rem;
}
.lg-header[data-v-3f8b0438] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-3f8b0438] {
  cursor: pointer;
}
.search-input-cm[data-v-3f8b0438] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-3f8b0438] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-3f8b0438],
.sm-item-grid[data-v-3f8b0438] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-3f8b0438] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-3f8b0438] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-3f8b0438] {
  background: #3b435e;
}
.sm-items[data-v-3f8b0438] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-3f8b0438] {
  background: #3b435e;
}
.col-grid[data-v-3f8b0438] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-3f8b0438] {
  font-weight: 500;
}
.list-item[data-v-3f8b0438] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-3f8b0438] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-3f8b0438] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-3f8b0438] {
  background: #3b435e;
}
.btn-container[data-v-3f8b0438] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-3f8b0438] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-3f8b0438] {
  background: #383f57;
}
.addTicketModal .header[data-v-3f8b0438] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-3f8b0438] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-3f8b0438] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-3f8b0438] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-3f8b0438] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-3f8b0438] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-3f8b0438] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-3f8b0438] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-3f8b0438] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-3f8b0438],
.add-ticket-details-grid .values-grid[data-v-3f8b0438] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-3f8b0438] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-3f8b0438] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-3f8b0438] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-3f8b0438] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-3f8b0438] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-3f8b0438] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-3f8b0438] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-3f8b0438] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-3f8b0438] {
  width: 100%;
}
.mapImg path.clickable[data-v-3f8b0438] {
  cursor: pointer;
}

.search-btn[data-v-e405c922] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  height: 1.75rem;
  padding: 0 0.625rem 0 0.75rem;
}
.h-40[data-v-e405c922] {
  height: 2.5rem;
}
.dark .search-btn[data-v-e405c922] {
  background: #353c53;
  box-shadow: 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
}
svg[data-v-e405c922] {
  cursor: pointer;
}
input[data-v-e405c922] {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-right: 0.3125rem;
}
.dark input[data-v-e405c922] {
  color: #f6f7ff;
}
.search-btn input[data-v-e405c922]::-webkit-input-placeholder {
  color: #abafd1;
}
.search-btn input[data-v-e405c922]::placeholder {
  color: #abafd1;
}
.dark .search-btn input[data-v-e405c922]::-webkit-input-placeholder {
  color: #5a5d74 !important;
}
.dark .search-btn input[data-v-e405c922]::placeholder {
  color: #5a5d74 !important;
}

.topbar[data-v-768f31c7] {
  display: flex;
  justify-content: space-between;
  padding: 1.4375rem 0;
  position: relative;
}
.pagename[data-v-768f31c7] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  display: flex;
  align-items: center;
}
.user[data-v-768f31c7] {
  display: flex;
  align-items: center;
}
.userimg[data-v-768f31c7] {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.userimg img[data-v-768f31c7] {
  width: 100%;
  height: 100%;
}
.username[data-v-768f31c7] {
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-left: 0.625rem;
}
.sm-link[data-v-768f31c7] {
  font-weight: 600;
  font-size: 0.9375rem;
  display: inline-block;
  cursor: pointer;
  line-height: 1.375rem;
  color: #abafd1;
}
.dark .sm-link[data-v-768f31c7] {
  color: #5a5d74;
}
.sm-link[data-v-768f31c7]:not(:last-child) {
  margin-right: 1.25rem;
}
.sm-links[data-v-768f31c7] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.active-link[data-v-768f31c7] {
  color: #323a52;
}
.dark .active-link[data-v-768f31c7] {
  color: #f6f7ff;
}
.logoutbtn[data-v-768f31c7] {
  /* padding: 1.5625rem 2.525rem; */
}
.circle[data-v-768f31c7] {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #4b4e93;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.productinfo[data-v-8da85a0d] {
  text-align: center;
  height: 100%;
  padding: 1.25rem 1.625rem 0 1.25rem;
  border-radius: 0.9375rem;
}
.categories[data-v-8da85a0d] {
  padding: 1.25rem 1.625rem 0 1.25rem;
}
.headersection[data-v-8da85a0d] {
  position: relative;
}
.headersection[data-v-8da85a0d]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: -0.25rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-8da85a0d]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.blur[data-v-8da85a0d] {
  -webkit-filter: blur(0.375rem);
          filter: blur(0.375rem);
  -webkit-user-select: none;
          user-select: none;
}
.smtext[data-v-8da85a0d] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.shoeimg[data-v-8da85a0d] {
  width: 10.9375rem !important;
  height: 5.9375rem !important;
  object-fit: contain !important;
  border-radius: 0.9375rem !important;
  background-color: #fff;
  margin-top: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  -webkit-border-radius: 0.9375rem !important;
  -moz-border-radius: 0.9375rem !important;
  -ms-border-radius: 0.9375rem !important;
  -o-border-radius: 0.9375rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.mb-comparing[data-v-8da85a0d] {
  margin-bottom: 0.5rem;
}
.bold[data-v-8da85a0d] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.mt-10[data-v-8da85a0d] {
  margin-top: 0.625rem;
}
.query[data-v-8da85a0d] {
  margin-right: 0.3125rem;
}
.large .query[data-v-8da85a0d],
.large .value[data-v-8da85a0d] {
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.pricing[data-v-8da85a0d] {
  text-align: center;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.red[data-v-8da85a0d],
.green[data-v-8da85a0d],
.blue[data-v-8da85a0d] {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.brown[data-v-8da85a0d] {
  background-color: #d07979;
}
.light-brown[data-v-8da85a0d] {
  background-color: #d0a879;
}
.light-green[data-v-8da85a0d] {
  background-color: #aad079;
}
.light-blue[data-v-8da85a0d] {
  background-color: #79c1d0;
}
.dark-brown[data-v-8da85a0d] {
  background-color: #b47467;
}
.pink[data-v-8da85a0d] {
  background-color: #d079c8;
}
.transaction-history .red[data-v-8da85a0d],
.transaction-history .green[data-v-8da85a0d] {
  font-weight: 300 !important;
  font-size: 0.9375rem;
}
.site[data-v-8da85a0d] {
  margin-bottom: 2rem;
}
.brand[data-v-8da85a0d] {
  width: 6rem;
  height: 3.25rem;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  object-fit: contain;
  padding: 0.1875rem;
}
.bids[data-v-8da85a0d] {
  margin-top: 1.0625rem;
  margin-bottom: 0.9375rem;
}
.al-st[data-v-8da85a0d] {
  align-items: flex-start;
}
.new[data-v-8da85a0d] {
  -webkit-backdrop-filter: blur(0.5625rem);
          backdrop-filter: blur(0.5625rem);
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  right: -0.3125rem;
  bottom: -0.3125rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  align-items: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.comparing[data-v-8da85a0d] {
  position: relative;
  padding: 1.25rem 1.625rem 0 1.25rem;
  height: 100%;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.b-shadow[data-v-8da85a0d] {
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
}
.value[data-v-8da85a0d] {
  font-weight: 300;
  font-size: 0.9375rem;
}
.large .value[data-v-8da85a0d] {
  font-weight: 400;
}
.categories-grid[data-v-8da85a0d] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem 0.3125rem;
  text-align: center;
}
.blue[data-v-8da85a0d] {
  background: #7983d0;
}
.lightblue[data-v-8da85a0d] {
  background: #79c1d0;
}
.yellow[data-v-8da85a0d] {
  background: #d0bd79;
}
.grey[data-v-8da85a0d] {
  background: #abafd1;
}
.smbox[data-v-8da85a0d] {
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  font-weight: 300 !important;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #ffffff !important;
  height: 2.5rem;
}
.cat[data-v-8da85a0d] {
  min-width: 7rem;
}
.text-center[data-v-8da85a0d] {
  text-align: center;
  margin-bottom: 1rem;
}
.categories-section[data-v-8da85a0d] {
  margin-top: 2rem;
}
.swiper[data-v-8da85a0d] {
  width: 100%;
  height: 100%;
}
.swiper-slide[data-v-8da85a0d] {
  text-align: center;
  font-size: 1.125rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6875rem;
}
.swiper-slide img[data-v-8da85a0d] {
  display: block;
  width: 100%;
  cursor: pointer;
  height: auto;
  object-fit: contain;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.trending[data-v-8da85a0d] {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  border-radius: 0.9375rem;
  overflow: hidden;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.pl[data-v-8da85a0d] {
  margin-left: 1.25rem;
  padding-top: 0.8125rem;
  padding-left: 0 !important  ;
}
.priceheader[data-v-8da85a0d] {
  margin-left: 1.25rem;
  padding-right: 1.1875rem;
  padding-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.priceheader.price-history[data-v-8da85a0d] {
  margin-bottom: 2.2rem;
}
.twogrid[data-v-8da85a0d] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2.125rem;
}
.twogrid.smgrid[data-v-8da85a0d] {
  padding: 0 !important;
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}
.shadow[data-v-8da85a0d] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.875rem 1.375rem;
}
.dark .shadow[data-v-8da85a0d] {
  background: #3b435e;
}
.shadow .bold[data-v-8da85a0d] {
  margin-bottom: 0.3125rem;
}
.mt-10[data-v-8da85a0d] {
  margin-top: 0.625rem !important;
}
.details[data-v-8da85a0d] {
  margin-left: 1.5625rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.type[data-v-8da85a0d] {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.cond[data-v-8da85a0d] {
  margin-left: 0.625rem;
}
.headersgrid[data-v-8da85a0d],
.item[data-v-8da85a0d] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.1fr 0.8fr;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .item[data-v-8da85a0d] {
  background-color: #3b435e;
}
.headersgrid div[data-v-8da85a0d],
.item div[data-v-8da85a0d] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.visible[data-v-8da85a0d] {
  overflow: visible !important;
}
.transaction-history .item img[data-v-8da85a0d] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.item[data-v-8da85a0d] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 14px 17px;
  margin-bottom: 0.3125rem;
  font-weight: 300;
}
.headersgrid[data-v-8da85a0d] {
  padding: 0 1.0625rem;
  font-weight: 500;
}
.transaction-history .headersgrid[data-v-8da85a0d] {
  padding: 0 2rem;
}
.transaction-history .item[data-v-8da85a0d] {
  padding: 0.375rem 0.625rem;
}
.transaction-history .headersgrid[data-v-8da85a0d],
.transaction-history .item[data-v-8da85a0d] {
  display: grid;
  grid-template-columns: 3.9375rem 1fr 0.4fr 0.6fr !important;
  grid-gap: 0.625rem !important;
}
.dark .transaction-history .item[data-v-8da85a0d] {
  background-color: #3b435e;
}
.pricing .blue[data-v-8da85a0d] {
  background: transparent !important;
}
.items[data-v-8da85a0d] {
  max-height: 18.85rem;
  overflow-y: auto;
  padding: 0.75rem 1rem;
  margin-right: 0.375rem;
  padding-right: 0.375rem;
}
.transaction-history .items[data-v-8da85a0d] {
  max-height: 41.35rem;
}
.transaction-history-owner .items[data-v-8da85a0d] {
  max-height: 20rem !important;
}
.headerscotainer[data-v-8da85a0d] {
  padding: 0 1rem;
}
.mt-15[data-v-8da85a0d] {
  margin-bottom: 0.3rem;
}
.mt-30[data-v-8da85a0d] {
  margin-bottom: 0.8rem;
}
.mt-0[data-v-8da85a0d] {
  margin-top: 0 !important;
}
.mt-18[data-v-8da85a0d] {
  margin-top: 1.125rem !important;
}
.home-categories[data-v-8da85a0d] {
  padding: 1rem 1.125rem;
}
.home-categories .query[data-v-8da85a0d],
.home-categories .value[data-v-8da85a0d] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr-10[data-v-8da85a0d] {
  padding-right: 4rem;
}
.staff-overviews .items[data-v-8da85a0d] {
  max-height: 13rem;
}
.item img[data-v-8da85a0d] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.total[data-v-8da85a0d] {
  margin-left: -3.5rem;
  font-weight: 600;
}
.totaltext[data-v-8da85a0d] {
  color: #abafd1;
}
.dark .totaltext[data-v-8da85a0d] {
  color: #5a5d74;
}
.admin-items .headersgrid[data-v-8da85a0d],
.admin-items .item[data-v-8da85a0d] {
  grid-template-columns: 0.5fr 1fr 0.8fr 0.6fr !important;
}
.admin-items .item img[data-v-8da85a0d] {
  margin-left: 0.3125rem;
}
.customer-details .items[data-v-8da85a0d] {
  max-height: 15rem;
}
.customer-details .item[data-v-8da85a0d] {
  grid-template-columns: 1fr 1fr !important;
}
.twogrid-section[data-v-8da85a0d] {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.header[data-v-648ad73a] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-648ad73a] {
  cursor: pointer;
}
.input[data-v-648ad73a] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-648ad73a] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-648ad73a] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-648ad73a] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-648ad73a] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-648ad73a] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-648ad73a] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-648ad73a] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-648ad73a] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-648ad73a] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-648ad73a] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-648ad73a] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-648ad73a] {
  font-size: 1.125rem;
}
.readOnly[data-v-648ad73a] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-648ad73a] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-648ad73a] {
  grid-column: span 2;
}
.breakup[data-v-648ad73a] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-648ad73a] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-648ad73a] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-648ad73a] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-648ad73a] {
  justify-content: space-evenly;
}
.jc-sb[data-v-648ad73a] {
  justify-content: space-between;
}
.btns[data-v-648ad73a] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-648ad73a] {
  padding: 0 1.25rem;
}
.pt-10[data-v-648ad73a] {
  padding-top: 0.625rem;
}
.table-container[data-v-648ad73a] {
  margin: 0 0.625rem;
}
.span-2[data-v-648ad73a] {
  grid-column: 2/4;
}
.green[data-v-648ad73a] {
  color: #12c519;
}
.mb-20[data-v-648ad73a] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-648ad73a] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-648ad73a] {
  padding: 0 1.25rem;
}
.lg-header[data-v-648ad73a] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-648ad73a] {
  cursor: pointer;
}
.search-input-cm[data-v-648ad73a] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-648ad73a] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-648ad73a],
.sm-item-grid[data-v-648ad73a] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-648ad73a] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-648ad73a] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-648ad73a] {
  background: #3b435e;
}
.sm-items[data-v-648ad73a] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-648ad73a] {
  background: #3b435e;
}
.col-grid[data-v-648ad73a] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-648ad73a] {
  font-weight: 500;
}
.list-item[data-v-648ad73a] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-648ad73a] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-648ad73a] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-648ad73a] {
  background: #3b435e;
}
.btn-container[data-v-648ad73a] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-648ad73a] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-648ad73a] {
  background: #383f57;
}
.addTicketModal .header[data-v-648ad73a] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-648ad73a] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-648ad73a] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-648ad73a] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-648ad73a] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-648ad73a] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-648ad73a] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-648ad73a] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-648ad73a] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-648ad73a],
.add-ticket-details-grid .values-grid[data-v-648ad73a] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-648ad73a] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-648ad73a] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-648ad73a] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-648ad73a] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-648ad73a] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-648ad73a] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-648ad73a] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-648ad73a] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-648ad73a] {
  width: 100%;
}
.mapImg path.clickable[data-v-648ad73a] {
  cursor: pointer;
}

.header[data-v-249bf589] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-249bf589] {
  cursor: pointer;
}
.input[data-v-249bf589] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-249bf589] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-249bf589] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-249bf589] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-249bf589] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-249bf589] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-249bf589] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-249bf589] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-249bf589] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-249bf589] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-249bf589] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-249bf589] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-249bf589] {
  font-size: 1.125rem;
}
.readOnly[data-v-249bf589] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-249bf589] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-249bf589] {
  grid-column: span 2;
}
.breakup[data-v-249bf589] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-249bf589] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-249bf589] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-249bf589] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-249bf589] {
  justify-content: space-evenly;
}
.jc-sb[data-v-249bf589] {
  justify-content: space-between;
}
.btns[data-v-249bf589] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-249bf589] {
  padding: 0 1.25rem;
}
.pt-10[data-v-249bf589] {
  padding-top: 0.625rem;
}
.table-container[data-v-249bf589] {
  margin: 0 0.625rem;
}
.span-2[data-v-249bf589] {
  grid-column: 2/4;
}
.green[data-v-249bf589] {
  color: #12c519;
}
.mb-20[data-v-249bf589] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-249bf589] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-249bf589] {
  padding: 0 1.25rem;
}
.lg-header[data-v-249bf589] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-249bf589] {
  cursor: pointer;
}
.search-input-cm[data-v-249bf589] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-249bf589] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-249bf589],
.sm-item-grid[data-v-249bf589] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-249bf589] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-249bf589] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-249bf589] {
  background: #3b435e;
}
.sm-items[data-v-249bf589] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-249bf589] {
  background: #3b435e;
}
.col-grid[data-v-249bf589] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-249bf589] {
  font-weight: 500;
}
.list-item[data-v-249bf589] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-249bf589] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-249bf589] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-249bf589] {
  background: #3b435e;
}
.btn-container[data-v-249bf589] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-249bf589] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-249bf589] {
  background: #383f57;
}
.addTicketModal .header[data-v-249bf589] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-249bf589] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-249bf589] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-249bf589] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-249bf589] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-249bf589] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-249bf589] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-249bf589] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-249bf589] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-249bf589],
.add-ticket-details-grid .values-grid[data-v-249bf589] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-249bf589] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-249bf589] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-249bf589] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-249bf589] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-249bf589] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-249bf589] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-249bf589] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-249bf589] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-249bf589] {
  width: 100%;
}
.mapImg path.clickable[data-v-249bf589] {
  cursor: pointer;
}

.items[data-v-ca893f25] {
  padding: 0 1rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.items-container[data-v-ca893f25] {
  overflow-y: auto;
  margin-right: 0.1875rem;
  max-height: 21rem;
  padding-bottom: 0.5rem;
}
.headersgrid[data-v-ca893f25],
.item[data-v-ca893f25] {
  display: grid;
  grid-template-columns: 3.9375rem 2.25fr 1.2fr 1fr 1.7fr 1.3fr 1.5fr 1.2fr;
  align-items: center;
  grid-gap: 1.5rem;
}
.headersgrid[data-v-ca893f25] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.4375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-right: 0.625rem;
}
.item[data-v-ca893f25] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding-top: 0.3125rem;
  padding-bottom: 0.5rem;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: 0.0625rem solid transparent;
  position: relative;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  cursor: pointer;
}
.dark .item[data-v-ca893f25] {
  background: #3b435e;
}
.item.item[data-v-ca893f25]:hover {
  border-color: #767bfa;
}
.item div[data-v-ca893f25],
.headersgrid div[data-v-ca893f25] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item img[data-v-ca893f25] {
  height: 2.3125rem;
  width: 100%;
}
.bgwhite[data-v-ca893f25] {
  background-color: #fff;
}
.green[data-v-ca893f25],
.Active[data-v-ca893f25] {
  color: #12c519;
}
.red[data-v-ca893f25],
.Expired[data-v-ca893f25] {
  color: #fa7676;
}
.Scheduled[data-v-ca893f25] {
  color: #d0a879;
}
.shoeimg[data-v-ca893f25] {
  width: 3.9375rem;
  object-fit: contain;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.discount-summary[data-v-ca893f25] {
  max-height: 19rem;
}
.discount-summary-header[data-v-ca893f25] {
  margin-top: 1.5rem !important;
}
.discount-summary-header[data-v-ca893f25],
.discount-summary .item[data-v-ca893f25] {
  grid-template-columns: 0.65fr 0.9fr 1.4fr 0.9fr 1fr 0.3fr 0.5fr;
  grid-gap: 1.1875rem;
}
.discount-summary .item[data-v-ca893f25] {
  padding-top: 0.8125rem;
  padding-bottom: 0.875rem;
}
.cap[data-v-ca893f25] {
  text-transform: uppercase;
}
.del-icon[data-v-ca893f25] {
  width: 1.25rem;
  height: 1.25rem !important;
}
.del-items .item[data-v-ca893f25],
.del-headers[data-v-ca893f25] {
  grid-template-columns: 0.65fr 0.9fr 1.4fr 0.9fr 1fr 0.3fr 0.5fr 1rem 1.25rem !important;
}
.t-headers[data-v-ca893f25],
.t-history .item[data-v-ca893f25] {
  grid-template-columns: 3.2rem 1.8fr 1fr 1fr 1fr 1fr 0.75fr;
}
.yellow[data-v-ca893f25] {
  color: #e7a33e;
}

.header[data-v-8386995f] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-8386995f] {
  cursor: pointer;
}
.row[data-v-8386995f] {
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.three-grid[data-v-8386995f] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.two-grid[data-v-8386995f] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.label[data-v-8386995f] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.jus-end[data-v-8386995f] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btns[data-v-8386995f] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-8386995f] {
  padding: 0 1.25rem;
}
.table-container[data-v-8386995f] {
  margin: 0 0.625rem;
}
.img-upload[data-v-8386995f] {
  background: #f6f7ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.dark .img-upload[data-v-8386995f] {
  background-color: #353c53;
}
.img-upload[data-v-8386995f] {
  cursor: pointer;
}
.text[data-v-8386995f] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.grey-text[data-v-8386995f] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-top: 0.75rem;
}
.ticket[data-v-8386995f] {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
.ticket[data-v-8386995f]:hover {
  background: #dfdfdf;
}
.dark .ticket[data-v-8386995f]:hover {
  background: #3b435e;
}
.ticket-img[data-v-8386995f] {
  max-width: 7rem;
  max-height: 7rem;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  margin-right: 0.625rem;
}
.search-results[data-v-8386995f] {
  margin-top: 0.625rem;
  padding: 0 1.25rem;
  max-height: 40rem;
  overflow-y: auto;
}
.ticket-title[data-v-8386995f] {
  font-size: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-description[data-v-8386995f] {
  font-size: 0.925rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-date[data-v-8386995f] {
  font-size: 0.9375rem;
  font-weight: 500;
}
.ticket-container[data-v-8386995f] {
  height: 100%;
}
.ticket-info[data-v-8386995f] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}

.tooltip[data-v-2f01e95c] {
  border-radius: 0.25rem;
  background: #fff;
  padding: 1rem;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(255, 255, 255, 0.25);
  text-align: center;
  color: #26313c;
}
.dark .tooltip[data-v-2f01e95c] {
  background-color: #26313c;
  color: #fff;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(0, 0, 0, 0.25);
}

.productinfo[data-v-2f01e95c] {
  text-align: center;
  height: 100%;
  padding: 1.25rem 1.625rem 0 1.25rem;
  border-radius: 0.9375rem;
}
.categories[data-v-2f01e95c] {
  padding: 1.25rem 1.625rem 0 1.25rem;
}
.headersection[data-v-2f01e95c] {
  position: relative;
}
.headersection[data-v-2f01e95c]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: -0.25rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-2f01e95c]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.blur[data-v-2f01e95c] {
  -webkit-filter: blur(0.375rem);
          filter: blur(0.375rem);
  -webkit-user-select: none;
          user-select: none;
}
.smtext[data-v-2f01e95c] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.shoeimg[data-v-2f01e95c] {
  width: 10.9375rem !important;
  height: 5.9375rem !important;
  object-fit: contain !important;
  border-radius: 0.9375rem !important;
  background-color: #fff;
  margin-top: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  -webkit-border-radius: 0.9375rem !important;
  -moz-border-radius: 0.9375rem !important;
  -ms-border-radius: 0.9375rem !important;
  -o-border-radius: 0.9375rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.mb-comparing[data-v-2f01e95c] {
  margin-bottom: 0.5rem;
}
.bold[data-v-2f01e95c] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.mt-10[data-v-2f01e95c] {
  margin-top: 0.625rem;
}
.query[data-v-2f01e95c] {
  margin-right: 0.3125rem;
}
.large .query[data-v-2f01e95c],
.large .value[data-v-2f01e95c] {
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.pricing[data-v-2f01e95c] {
  text-align: center;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.red[data-v-2f01e95c],
.green[data-v-2f01e95c],
.blue[data-v-2f01e95c] {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.brown[data-v-2f01e95c] {
  background-color: #d07979;
}
.light-brown[data-v-2f01e95c] {
  background-color: #d0a879;
}
.light-green[data-v-2f01e95c] {
  background-color: #aad079;
}
.light-blue[data-v-2f01e95c] {
  background-color: #79c1d0;
}
.dark-brown[data-v-2f01e95c] {
  background-color: #b47467;
}
.pink[data-v-2f01e95c] {
  background-color: #d079c8;
}
.transaction-history .red[data-v-2f01e95c],
.transaction-history .green[data-v-2f01e95c] {
  font-weight: 300 !important;
  font-size: 0.9375rem;
}
.site[data-v-2f01e95c] {
  margin-bottom: 2rem;
}
.brand[data-v-2f01e95c] {
  width: 6rem;
  height: 3.25rem;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  object-fit: contain;
  padding: 0.1875rem;
}
.bids[data-v-2f01e95c] {
  margin-top: 1.0625rem;
  margin-bottom: 0.9375rem;
}
.al-st[data-v-2f01e95c] {
  align-items: flex-start;
}
.new[data-v-2f01e95c] {
  -webkit-backdrop-filter: blur(0.5625rem);
          backdrop-filter: blur(0.5625rem);
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  right: -0.3125rem;
  bottom: -0.3125rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  align-items: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.comparing[data-v-2f01e95c] {
  position: relative;
  padding: 1.25rem 1.625rem 0 1.25rem;
  height: 100%;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.b-shadow[data-v-2f01e95c] {
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
}
.value[data-v-2f01e95c] {
  font-weight: 300;
  font-size: 0.9375rem;
}
.large .value[data-v-2f01e95c] {
  font-weight: 400;
}
.categories-grid[data-v-2f01e95c] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem 0.3125rem;
  text-align: center;
}
.blue[data-v-2f01e95c] {
  background: #7983d0;
}
.lightblue[data-v-2f01e95c] {
  background: #79c1d0;
}
.yellow[data-v-2f01e95c] {
  background: #d0bd79;
}
.grey[data-v-2f01e95c] {
  background: #abafd1;
}
.smbox[data-v-2f01e95c] {
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  font-weight: 300 !important;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #ffffff !important;
  height: 2.5rem;
}
.cat[data-v-2f01e95c] {
  min-width: 7rem;
}
.text-center[data-v-2f01e95c] {
  text-align: center;
  margin-bottom: 1rem;
}
.categories-section[data-v-2f01e95c] {
  margin-top: 2rem;
}
.swiper[data-v-2f01e95c] {
  width: 100%;
  height: 100%;
}
.swiper-slide[data-v-2f01e95c] {
  text-align: center;
  font-size: 1.125rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6875rem;
}
.swiper-slide img[data-v-2f01e95c] {
  display: block;
  width: 100%;
  cursor: pointer;
  height: auto;
  object-fit: contain;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.trending[data-v-2f01e95c] {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  border-radius: 0.9375rem;
  overflow: hidden;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.pl[data-v-2f01e95c] {
  margin-left: 1.25rem;
  padding-top: 0.8125rem;
  padding-left: 0 !important  ;
}
.priceheader[data-v-2f01e95c] {
  margin-left: 1.25rem;
  padding-right: 1.1875rem;
  padding-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.priceheader.price-history[data-v-2f01e95c] {
  margin-bottom: 2.2rem;
}
.twogrid[data-v-2f01e95c] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2.125rem;
}
.twogrid.smgrid[data-v-2f01e95c] {
  padding: 0 !important;
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}
.shadow[data-v-2f01e95c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.875rem 1.375rem;
}
.dark .shadow[data-v-2f01e95c] {
  background: #3b435e;
}
.shadow .bold[data-v-2f01e95c] {
  margin-bottom: 0.3125rem;
}
.mt-10[data-v-2f01e95c] {
  margin-top: 0.625rem !important;
}
.details[data-v-2f01e95c] {
  margin-left: 1.5625rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.type[data-v-2f01e95c] {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.cond[data-v-2f01e95c] {
  margin-left: 0.625rem;
}
.headersgrid[data-v-2f01e95c],
.item[data-v-2f01e95c] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.1fr 0.8fr;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .item[data-v-2f01e95c] {
  background-color: #3b435e;
}
.headersgrid div[data-v-2f01e95c],
.item div[data-v-2f01e95c] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.visible[data-v-2f01e95c] {
  overflow: visible !important;
}
.transaction-history .item img[data-v-2f01e95c] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.item[data-v-2f01e95c] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 14px 17px;
  margin-bottom: 0.3125rem;
  font-weight: 300;
}
.headersgrid[data-v-2f01e95c] {
  padding: 0 1.0625rem;
  font-weight: 500;
}
.transaction-history .headersgrid[data-v-2f01e95c] {
  padding: 0 2rem;
}
.transaction-history .item[data-v-2f01e95c] {
  padding: 0.375rem 0.625rem;
}
.transaction-history .headersgrid[data-v-2f01e95c],
.transaction-history .item[data-v-2f01e95c] {
  display: grid;
  grid-template-columns: 3.9375rem 1fr 0.4fr 0.6fr !important;
  grid-gap: 0.625rem !important;
}
.dark .transaction-history .item[data-v-2f01e95c] {
  background-color: #3b435e;
}
.pricing .blue[data-v-2f01e95c] {
  background: transparent !important;
}
.items[data-v-2f01e95c] {
  max-height: 18.85rem;
  overflow-y: auto;
  padding: 0.75rem 1rem;
  margin-right: 0.375rem;
  padding-right: 0.375rem;
}
.transaction-history .items[data-v-2f01e95c] {
  max-height: 41.35rem;
}
.transaction-history-owner .items[data-v-2f01e95c] {
  max-height: 20rem !important;
}
.headerscotainer[data-v-2f01e95c] {
  padding: 0 1rem;
}
.mt-15[data-v-2f01e95c] {
  margin-bottom: 0.3rem;
}
.mt-30[data-v-2f01e95c] {
  margin-bottom: 0.8rem;
}
.mt-0[data-v-2f01e95c] {
  margin-top: 0 !important;
}
.mt-18[data-v-2f01e95c] {
  margin-top: 1.125rem !important;
}
.home-categories[data-v-2f01e95c] {
  padding: 1rem 1.125rem;
}
.home-categories .query[data-v-2f01e95c],
.home-categories .value[data-v-2f01e95c] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr-10[data-v-2f01e95c] {
  padding-right: 4rem;
}
.staff-overviews .items[data-v-2f01e95c] {
  max-height: 13rem;
}
.item img[data-v-2f01e95c] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.total[data-v-2f01e95c] {
  margin-left: -3.5rem;
  font-weight: 600;
}
.totaltext[data-v-2f01e95c] {
  color: #abafd1;
}
.dark .totaltext[data-v-2f01e95c] {
  color: #5a5d74;
}
.admin-items .headersgrid[data-v-2f01e95c],
.admin-items .item[data-v-2f01e95c] {
  grid-template-columns: 0.5fr 1fr 0.8fr 0.6fr !important;
}
.admin-items .item img[data-v-2f01e95c] {
  margin-left: 0.3125rem;
}
.customer-details .items[data-v-2f01e95c] {
  max-height: 15rem;
}
.customer-details .item[data-v-2f01e95c] {
  grid-template-columns: 1fr 1fr !important;
}
.twogrid-section[data-v-2f01e95c] {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.tooltip[data-v-6a02ab56] {
  border-radius: 0.25rem;
  background: #fff;
  padding: 1rem;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(255, 255, 255, 0.25);
  text-align: center;
  color: #26313c;
}
.dark .tooltip[data-v-6a02ab56] {
  background-color: #26313c;
  color: #fff;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(0, 0, 0, 0.25);
}

.formControl[data-v-3a3288fb] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  margin: 0px !important;
}
.dark .formControl[data-v-3a3288fb] {
  background: #353c53;
}
.menuitem[data-v-3a3288fb] {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: left;
  justify-content: center;
  color: #abafd1;
}
.menuitem[data-v-3a3288fb]:hover {
  background-color: rgba(118, 123, 250, 0.15) !important;
}
.dark .menuitem[data-v-3a3288fb] {
  color: #f6f7ff !important;
}
.menuitem.focusVisible[data-v-3a3288fb] {
  background-color: rgba(118, 123, 250, 0.15) !important;
}

.headersection[data-v-45c8a8f7] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-45c8a8f7]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-45c8a8f7]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.green[data-v-45c8a8f7] {
  color: #12c519;
}
.black[data-v-45c8a8f7] {
  margin-right: 0.3125rem;
  color: #323a52 !important;
}
.dark .black[data-v-45c8a8f7] {
  color: #f6f7ff !important;
}
.itemssold[data-v-45c8a8f7] {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.35rem;
  margin-left: 0.625rem;
}
.al-fe[data-v-45c8a8f7] {
  align-items: flex-end;
}
.px-20[data-v-45c8a8f7] {
  padding: 0 1.25rem;
}
.headers[data-v-45c8a8f7] {
  margin-bottom: 0.875rem;
}
.bold[data-v-45c8a8f7] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}
.transaction-history[data-v-45c8a8f7] {
  grid-row: span 2;
}
.type[data-v-45c8a8f7]:not(:last-child) {
  margin-right: 1.5625rem;
}
.type[data-v-45c8a8f7] {
  color: #abafd1;
  cursor: pointer;
}
.type.active[data-v-45c8a8f7] {
  color: #323a52;
}
.dark .type[data-v-45c8a8f7] {
  color: #5a5d74;
}
.dark .type.active[data-v-45c8a8f7] {
  color: #f6f7ff;
}

.items[data-v-45c8a8f7] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23.6875rem, 1fr));
  grid-gap: 2rem;
}
.item[data-v-45c8a8f7] {
  min-height: 24.5625rem;
}
.items-container[data-v-45c8a8f7] {
  padding-top: 0.5rem;
  max-height: 85vh;
  overflow-x: auto;
  padding-bottom: 2rem;
}
.threegrid[data-v-45c8a8f7] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 19.5625rem 26.25rem;
  grid-gap: 1.8125rem;
}
.box[data-v-45c8a8f7] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.span-2[data-v-45c8a8f7] {
  grid-column: span 2;
}
.twogrid[data-v-45c8a8f7] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.inventory-container[data-v-8387de67] {
 background: #f6f7ff;
 box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
 border-radius: 0.9375rem;
 padding: 0.8125rem 0;
}
.dark .inventory-container[data-v-8387de67] {
 background: #353c53;
}
.headersection[data-v-8387de67] {
 position: relative;
 padding: 0 1rem;
}
.headersection[data-v-8387de67]::before {
 position: absolute;
 content: "";
 height: 0.0625rem;
 width: 7.3125rem;
 bottom: -0.25rem;
 left: 1rem;
 background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
 background-size: contain;
 background-repeat: no-repeat;
}
.dark .headersection[data-v-8387de67]::before {
 background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.purple[data-v-8387de67] {
 font-weight: 500;
 color: #767bfa;
}
.query[data-v-8387de67]:not(:last-child) {
 margin-right: 2.5rem;
}
.query[data-v-8387de67] {
 font-size: 0.9375rem;
 line-height: 1.375rem;
}
.smtext[data-v-8387de67] {
 font-weight: 600;
 font-size: 0.9375rem;
 line-height: 1.375rem;
 padding-left: 0.25rem;
}
.queryvalue[data-v-8387de67] {
 font-weight: 300;

 margin-left: 0.625rem;
}
.green[data-v-8387de67] {
 color: #12c519;
}

.header[data-v-4254f40d] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-4254f40d] {
  cursor: pointer;
}
.input[data-v-4254f40d] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-4254f40d] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-4254f40d] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-4254f40d] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-4254f40d] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-4254f40d] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-4254f40d] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-4254f40d] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-4254f40d] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-4254f40d] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-4254f40d] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-4254f40d] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-4254f40d] {
  font-size: 1.125rem;
}
.readOnly[data-v-4254f40d] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-4254f40d] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-4254f40d] {
  grid-column: span 2;
}
.breakup[data-v-4254f40d] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-4254f40d] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-4254f40d] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-4254f40d] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-4254f40d] {
  justify-content: space-evenly;
}
.jc-sb[data-v-4254f40d] {
  justify-content: space-between;
}
.btns[data-v-4254f40d] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-4254f40d] {
  padding: 0 1.25rem;
}
.pt-10[data-v-4254f40d] {
  padding-top: 0.625rem;
}
.table-container[data-v-4254f40d] {
  margin: 0 0.625rem;
}
.span-2[data-v-4254f40d] {
  grid-column: 2/4;
}
.green[data-v-4254f40d] {
  color: #12c519;
}
.mb-20[data-v-4254f40d] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-4254f40d] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-4254f40d] {
  padding: 0 1.25rem;
}
.lg-header[data-v-4254f40d] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-4254f40d] {
  cursor: pointer;
}
.search-input-cm[data-v-4254f40d] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-4254f40d] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-4254f40d],
.sm-item-grid[data-v-4254f40d] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-4254f40d] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-4254f40d] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-4254f40d] {
  background: #3b435e;
}
.sm-items[data-v-4254f40d] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-4254f40d] {
  background: #3b435e;
}
.col-grid[data-v-4254f40d] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-4254f40d] {
  font-weight: 500;
}
.list-item[data-v-4254f40d] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-4254f40d] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-4254f40d] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-4254f40d] {
  background: #3b435e;
}
.btn-container[data-v-4254f40d] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-4254f40d] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-4254f40d] {
  background: #383f57;
}
.addTicketModal .header[data-v-4254f40d] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-4254f40d] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-4254f40d] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-4254f40d] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-4254f40d] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-4254f40d] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-4254f40d] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-4254f40d] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-4254f40d] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-4254f40d],
.add-ticket-details-grid .values-grid[data-v-4254f40d] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-4254f40d] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-4254f40d] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-4254f40d] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-4254f40d] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-4254f40d] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-4254f40d] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-4254f40d] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-4254f40d] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-4254f40d] {
  width: 100%;
}
.mapImg path.clickable[data-v-4254f40d] {
  cursor: pointer;
}

th[data-v-76dc02f7] {
  background-color: #f6f7ff;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: none;
}
.dark th[data-v-76dc02f7] {
  background-color: #323a52;
}
table[data-v-76dc02f7] {
  border-spacing: 0 0.3125rem;
}
th[data-v-76dc02f7] {
  padding: 0;
}
td[data-v-76dc02f7] {
  padding: 0.3125rem 0;
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 300;
}
td[data-v-76dc02f7]:first-child {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
td[data-v-76dc02f7]:last-child {
  padding-right: 0.625rem;
}
td[data-v-76dc02f7]:nth-child(3) {
  padding-right: 1.425rem;
}
tr[data-v-76dc02f7] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
}
.dark tr[data-v-76dc02f7] {
  background-color: #3b435e;
  color: #f6f7ff;
}
.dark td[data-v-76dc02f7],
.dark th[data-v-76dc02f7] {
  color: #f6f7ff;
}
.shadow-none[data-v-76dc02f7] {
  box-shadow: none !important;
}
.dark .shadow-none[data-v-76dc02f7] {
  background-color: #323a52;
}
.table[data-v-76dc02f7] {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.625rem;
  margin: 0 0.625remq;
}
.sku img[data-v-76dc02f7] {
  max-width: 3.9375rem;
  height: auto;
}
.dark .sku img[data-v-76dc02f7] {
  max-width: none;
  width: 100%;
  height: 2.8125rem;
  object-fit: contain;
  background-color: #fff;
  border-radius: 0.3125rem;
}
td[data-v-76dc02f7] {
  border-bottom: 0;
}
tr td[data-v-76dc02f7]:first-child {
  border-top-left-radius: 0.4375rem;
}
tr td[data-v-76dc02f7]:last-child {
  border-top-right-radius: 0.4375rem;
}
tr td[data-v-76dc02f7]:first-child {
  border-bottom-left-radius: 0.4375rem;
}
tr td[data-v-76dc02f7]:last-child {
  border-bottom-right-radius: 0.4375rem;
}
tr th[data-v-76dc02f7]:first-child {
  padding-left: 1rem;
}
.capitalize[data-v-76dc02f7] {
  text-transform: capitalize;
}
.green[data-v-76dc02f7] {
  color: #8FF093;
}
.headers-grid[data-v-b8baaa36] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-b8baaa36] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.item-grid[data-v-b8baaa36] {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.2fr 0.9fr 1.15fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  height: 3.125rem;
  align-items: center;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-b8baaa36] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr  1.25fr 1.25fr 1.1fr 1.1fr 2.8fr 0.6fr 1fr 0.9fr;
}
.dark .item-grid[data-v-b8baaa36] {
  background: #3b435e;
}
.item-grid[data-v-b8baaa36]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-b8baaa36],
.headers-grid div[data-v-b8baaa36] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-b8baaa36] {
  cursor: pointer;
}
.green[data-v-b8baaa36] {
  color: #12c519;
}
.items-container[data-v-b8baaa36] {
  max-height: 41.15rem;
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-b8baaa36] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.capitalize[data-v-b8baaa36] {
  text-transform: capitalize;
}
.red[data-v-b8baaa36] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-b8baaa36] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-b8baaa36] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-b8baaa36] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-b8baaa36] {
  overflow: visible !important;
}
.purple[data-v-b8baaa36] {
  color: #767BFA;
}
.header[data-v-3e4163c3] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-3e4163c3] {
  cursor: pointer;
}
.input[data-v-3e4163c3] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-3e4163c3] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-3e4163c3] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-3e4163c3] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-3e4163c3] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-3e4163c3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-3e4163c3] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-3e4163c3] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-3e4163c3] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-3e4163c3] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-3e4163c3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-3e4163c3] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-3e4163c3] {
  font-size: 1.125rem;
}
.readOnly[data-v-3e4163c3] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-3e4163c3] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-3e4163c3] {
  grid-column: span 2;
}
.breakup[data-v-3e4163c3] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-3e4163c3] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-3e4163c3] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-3e4163c3] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-3e4163c3] {
  justify-content: space-evenly;
}
.jc-sb[data-v-3e4163c3] {
  justify-content: space-between;
}
.btns[data-v-3e4163c3] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-3e4163c3] {
  padding: 0 1.25rem;
}
.pt-10[data-v-3e4163c3] {
  padding-top: 0.625rem;
}
.table-container[data-v-3e4163c3] {
  margin: 0 0.625rem;
}
.span-2[data-v-3e4163c3] {
  grid-column: 2/4;
}
.green[data-v-3e4163c3] {
  color: #12c519;
}
.mb-20[data-v-3e4163c3] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-3e4163c3] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-3e4163c3] {
  padding: 0 1.25rem;
}
.lg-header[data-v-3e4163c3] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-3e4163c3] {
  cursor: pointer;
}
.search-input-cm[data-v-3e4163c3] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-3e4163c3] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-3e4163c3],
.sm-item-grid[data-v-3e4163c3] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-3e4163c3] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-3e4163c3] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-3e4163c3] {
  background: #3b435e;
}
.sm-items[data-v-3e4163c3] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-3e4163c3] {
  background: #3b435e;
}
.col-grid[data-v-3e4163c3] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-3e4163c3] {
  font-weight: 500;
}
.list-item[data-v-3e4163c3] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-3e4163c3] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-3e4163c3] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-3e4163c3] {
  background: #3b435e;
}
.btn-container[data-v-3e4163c3] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-3e4163c3] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-3e4163c3] {
  background: #383f57;
}
.addTicketModal .header[data-v-3e4163c3] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-3e4163c3] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-3e4163c3] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-3e4163c3] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-3e4163c3] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-3e4163c3] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-3e4163c3] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-3e4163c3] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-3e4163c3] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-3e4163c3],
.add-ticket-details-grid .values-grid[data-v-3e4163c3] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-3e4163c3] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-3e4163c3] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-3e4163c3] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-3e4163c3] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-3e4163c3] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-3e4163c3] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-3e4163c3] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-3e4163c3] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-3e4163c3] {
  width: 100%;
}
.mapImg path.clickable[data-v-3e4163c3] {
  cursor: pointer;
}

th[data-v-d7626eb0] {
  background-color: #f6f7ff;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: none;
}
.dark th[data-v-d7626eb0] {
  background-color: #323a52;
}
table[data-v-d7626eb0] {
  border-spacing: 0 0.3125rem;
}
th[data-v-d7626eb0] {
  padding: 0;
}
td[data-v-d7626eb0] {
  padding: 0.3125rem 0;
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td[data-v-d7626eb0]:first-child,
td[data-v-d7626eb0]:last-child {
  padding: 0.3125rem 0.625rem;
}
tr[data-v-d7626eb0] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
}
.dark tr[data-v-d7626eb0] {
  background-color: #3b435e;
  color: #f6f7ff;
}
.dark td[data-v-d7626eb0],
.dark th[data-v-d7626eb0] {
  color: #f6f7ff;
}
.shadow-none[data-v-d7626eb0] {
  box-shadow: none !important;
}
.dark .shadow-none[data-v-d7626eb0] {
  background-color: #323a52;
}
.table[data-v-d7626eb0] {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.625rem;
  margin: 0 0.625remq;
}
.sku img[data-v-d7626eb0] {
  max-width: 3.9375rem;
  height: auto;
}
.dark .sku img[data-v-d7626eb0] {
  max-width: none;
  width: 100%;
  height: 2.8125rem;
  object-fit: contain;
  background-color: #fff;
  border-radius: 0.3125rem;
}
td[data-v-d7626eb0] {
  border-bottom: 0;
}
tr td[data-v-d7626eb0]:first-child {
  border-top-left-radius: 0.4375rem;
}
tr td[data-v-d7626eb0]:last-child {
  border-top-right-radius: 0.4375rem;
}
tr td[data-v-d7626eb0]:first-child {
  border-bottom-left-radius: 0.4375rem;
}
tr td[data-v-d7626eb0]:last-child {
  border-bottom-right-radius: 0.4375rem;
}
tr th[data-v-d7626eb0]:first-child {
  padding-left: 1rem;
}
.capitalize[data-v-d7626eb0] {
  text-transform: capitalize;
}


.header[data-v-98745642] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-98745642] {
  cursor: pointer;
}
.input[data-v-98745642] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-98745642] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-98745642] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-98745642] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-98745642] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-98745642] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-98745642] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-98745642] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-98745642] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-98745642] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-98745642] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-98745642] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-98745642] {
  font-size: 1.125rem;
}
.readOnly[data-v-98745642] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-98745642] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-98745642] {
  grid-column: span 2;
}
.breakup[data-v-98745642] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-98745642] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-98745642] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-98745642] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-98745642] {
  justify-content: space-evenly;
}
.jc-sb[data-v-98745642] {
  justify-content: space-between;
}
.btns[data-v-98745642] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-98745642] {
  padding: 0 1.25rem;
}
.pt-10[data-v-98745642] {
  padding-top: 0.625rem;
}
.table-container[data-v-98745642] {
  margin: 0 0.625rem;
}
.span-2[data-v-98745642] {
  grid-column: 2/4;
}
.green[data-v-98745642] {
  color: #12c519;
}
.mb-20[data-v-98745642] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-98745642] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-98745642] {
  padding: 0 1.25rem;
}
.lg-header[data-v-98745642] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-98745642] {
  cursor: pointer;
}
.search-input-cm[data-v-98745642] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-98745642] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-98745642],
.sm-item-grid[data-v-98745642] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-98745642] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-98745642] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-98745642] {
  background: #3b435e;
}
.sm-items[data-v-98745642] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-98745642] {
  background: #3b435e;
}
.col-grid[data-v-98745642] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-98745642] {
  font-weight: 500;
}
.list-item[data-v-98745642] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-98745642] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-98745642] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-98745642] {
  background: #3b435e;
}
.btn-container[data-v-98745642] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-98745642] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-98745642] {
  background: #383f57;
}
.addTicketModal .header[data-v-98745642] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-98745642] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-98745642] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-98745642] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-98745642] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-98745642] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-98745642] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-98745642] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-98745642] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-98745642],
.add-ticket-details-grid .values-grid[data-v-98745642] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-98745642] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-98745642] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-98745642] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-98745642] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-98745642] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-98745642] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-98745642] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-98745642] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-98745642] {
  width: 100%;
}
.mapImg path.clickable[data-v-98745642] {
  cursor: pointer;
}

th[data-v-e41ea8c6] {
  background-color: #f6f7ff;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  border: none;
}
.dark th[data-v-e41ea8c6] {
  background-color: #323a52;
}
table[data-v-e41ea8c6] {
  border-spacing: 0 0.3125rem;
}
th[data-v-e41ea8c6] {
  padding: 0;
}
td[data-v-e41ea8c6] {
  padding: 0.3125rem 0;
  max-width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td[data-v-e41ea8c6]:first-child,
td[data-v-e41ea8c6]:last-child {
  padding: 0.3125rem 0.625rem;
}
tr[data-v-e41ea8c6] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
}
.dark tr[data-v-e41ea8c6] {
  background-color: #3b435e;
  color: #f6f7ff;
}
.dark td[data-v-e41ea8c6],
.dark th[data-v-e41ea8c6] {
  color: #f6f7ff;
}
.shadow-none[data-v-e41ea8c6] {
  box-shadow: none !important;
}
.dark .shadow-none[data-v-e41ea8c6] {
  background-color: #323a52;
}
.table[data-v-e41ea8c6] {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.625rem;
  margin: 0 0.625remq;
}
.sku img[data-v-e41ea8c6] {
  max-width: 3.9375rem;
  height: auto;
}
.dark .sku img[data-v-e41ea8c6] {
  max-width: none;
  width: 100%;
  height: 2.8125rem;
  object-fit: contain;
  background-color: #fff;
  border-radius: 0.3125rem;
}
td[data-v-e41ea8c6] {
  border-bottom: 0;
}
tr td[data-v-e41ea8c6]:first-child {
  border-top-left-radius: 0.4375rem;
}
tr td[data-v-e41ea8c6]:last-child {
  border-top-right-radius: 0.4375rem;
}
tr td[data-v-e41ea8c6]:first-child {
  border-bottom-left-radius: 0.4375rem;
}
tr td[data-v-e41ea8c6]:last-child {
  border-bottom-right-radius: 0.4375rem;
}
tr th[data-v-e41ea8c6]:first-child {
  padding-left: 1rem;
}
.capitalize[data-v-e41ea8c6] {
  text-transform: capitalize;
}


.header[data-v-c733dcf2] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-c733dcf2] {
  cursor: pointer;
}
.input[data-v-c733dcf2] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-c733dcf2] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-c733dcf2] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-c733dcf2] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-c733dcf2] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-c733dcf2] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-c733dcf2] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-c733dcf2] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-c733dcf2] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-c733dcf2] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-c733dcf2] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-c733dcf2] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-c733dcf2] {
  font-size: 1.125rem;
}
.readOnly[data-v-c733dcf2] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-c733dcf2] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-c733dcf2] {
  grid-column: span 2;
}
.breakup[data-v-c733dcf2] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-c733dcf2] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-c733dcf2] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-c733dcf2] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-c733dcf2] {
  justify-content: space-evenly;
}
.jc-sb[data-v-c733dcf2] {
  justify-content: space-between;
}
.btns[data-v-c733dcf2] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-c733dcf2] {
  padding: 0 1.25rem;
}
.pt-10[data-v-c733dcf2] {
  padding-top: 0.625rem;
}
.table-container[data-v-c733dcf2] {
  margin: 0 0.625rem;
}
.span-2[data-v-c733dcf2] {
  grid-column: 2/4;
}
.green[data-v-c733dcf2] {
  color: #12c519;
}
.mb-20[data-v-c733dcf2] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-c733dcf2] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-c733dcf2] {
  padding: 0 1.25rem;
}
.lg-header[data-v-c733dcf2] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-c733dcf2] {
  cursor: pointer;
}
.search-input-cm[data-v-c733dcf2] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-c733dcf2] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-c733dcf2],
.sm-item-grid[data-v-c733dcf2] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-c733dcf2] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-c733dcf2] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-c733dcf2] {
  background: #3b435e;
}
.sm-items[data-v-c733dcf2] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-c733dcf2] {
  background: #3b435e;
}
.col-grid[data-v-c733dcf2] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-c733dcf2] {
  font-weight: 500;
}
.list-item[data-v-c733dcf2] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-c733dcf2] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-c733dcf2] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-c733dcf2] {
  background: #3b435e;
}
.btn-container[data-v-c733dcf2] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-c733dcf2] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-c733dcf2] {
  background: #383f57;
}
.addTicketModal .header[data-v-c733dcf2] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-c733dcf2] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-c733dcf2] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-c733dcf2] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-c733dcf2] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-c733dcf2] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-c733dcf2] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-c733dcf2] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-c733dcf2] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-c733dcf2],
.add-ticket-details-grid .values-grid[data-v-c733dcf2] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-c733dcf2] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-c733dcf2] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-c733dcf2] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-c733dcf2] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-c733dcf2] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-c733dcf2] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-c733dcf2] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-c733dcf2] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-c733dcf2] {
  width: 100%;
}
.mapImg path.clickable[data-v-c733dcf2] {
  cursor: pointer;
}

.header[data-v-f2227036] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-f2227036] {
  cursor: pointer;
}
.row[data-v-f2227036] {
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.three-grid[data-v-f2227036] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.two-grid[data-v-f2227036] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
}
.label[data-v-f2227036] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.jus-end[data-v-f2227036] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btns[data-v-f2227036] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-f2227036] {
  padding: 0 1.25rem;
}
.table-container[data-v-f2227036] {
  margin: 0 0.625rem;
}
.img-upload[data-v-f2227036] {
  background: #f6f7ff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.dark .img-upload[data-v-f2227036] {
  background-color: #353c53;
}
.img-upload[data-v-f2227036] {
  cursor: pointer;
}
.text[data-v-f2227036] {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.grey-text[data-v-f2227036] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-top: 0.75rem;
}
.ticket[data-v-f2227036] {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
.ticket[data-v-f2227036]:hover {
  background: #dfdfdf;
}
.dark .ticket[data-v-f2227036]:hover {
  background: #3b435e;
}
.ticket-img[data-v-f2227036] {
  max-width: 7rem;
  max-height: 7rem;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  margin-right: 0.625rem;
}
.search-results[data-v-f2227036] {
  margin-top: 0.625rem;
  padding: 0 1.25rem;
  max-height: 40rem;
  overflow-y: auto;
}
.ticket-title[data-v-f2227036] {
  font-size: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-description[data-v-f2227036] {
  font-size: 0.925rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-date[data-v-f2227036] {
  font-size: 0.9375rem;
  font-weight: 500;
}
.ticket-container[data-v-f2227036] {
  height: 100%;
}
.ticket-info[data-v-f2227036] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}

.headers-grid[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid.listed[data-v-22672f32] {
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
}
.item-grid[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid.listed[data-v-22672f32] {
  grid-template-columns: 2.5fr 3fr 2fr 3.5fr 2.5fr 2.75fr 1.5fr 3fr;
}
.dark .item-grid[data-v-22672f32] {
  background: #3b435e;
}
.item-grid[data-v-22672f32]:hover {
  border-color: #767bfa;
}
.item-grid div[data-v-22672f32],
.headers-grid div[data-v-22672f32] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-grid svg[data-v-22672f32] {
  cursor: pointer;
}
.green[data-v-22672f32] {
  color: #12c519;
}
.date[data-v-22672f32] {
  white-space: break-spaces !important;
  text-align: right !important;
  color: #767bfa;
}
.items-container[data-v-22672f32] {
  /* max-height: 41.15rem; */
  overflow-y: auto;
  margin-right: 0.625rem;
}
.items[data-v-22672f32] {
  padding: 0 1rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
  height: 78vh;
}
.capitalize[data-v-22672f32] {
  text-transform: capitalize;
}
.red[data-v-22672f32] {
  color: rgb(250, 118, 118);
}
.consignee[data-v-22672f32] {
  grid-template-columns: 1fr 1fr 2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.25fr 1.2fr 1fr;
  grid-gap: 0.375rem;
}
.consignee-summary[data-v-22672f32] {
  grid-template-columns: 3.5fr 1.5fr 1fr 1.5fr 1.75fr 1.75fr 1.25fr 1.25fr 1.1fr 1.1fr 1.45fr !important;
  grid-gap: 0.375rem;
}
@-moz-document url-prefix() {
.headers-grid {
    width: calc(100% - 2.5rem) !important;
}
}
.expense-grid[data-v-22672f32] {
  grid-template-columns: repeat(9, 1fr) !important;
}
.items .btnWrap[data-v-22672f32] {
  overflow: visible !important;
}
.purple[data-v-22672f32] {
  color: #767bfa;
}
.headers-grid-listed[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
  grid-gap: 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 500;

  margin-top: 0.6875rem;
  height: 2.5rem;
  align-items: center;
  width: calc(100% - 1.625rem);
  padding-left: 2.25rem;
  padding-right: 0.3125rem;
}
.headers-grid-listed.listed[data-v-22672f32] {
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
}
.item-grid-listed[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.item-grid-listed.listed[data-v-22672f32] {
  grid-template-columns: 2.5fr 3fr 3.5fr 3.5fr 2.5fr 3.5fr 2.5fr;
}
.shareBtnWrap[data-v-22672f32] {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3.6rem;
}
.ticket-container[data-v-22672f32] {
  /* max-height: 46rem; */
}
.ticket-collapse-parent-grid[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2rem repeat(5, 1fr);
  align-items: center;
  padding: 0 1rem;
  grid-gap: 0 0.5rem;
  cursor: pointer;
}
.ticket-collapse-child-grid[data-v-22672f32],
.ticket-collapse-child-values-grid[data-v-22672f32] {
  display: grid;
  grid-template-columns: 2rem repeat(6, 1.3fr) 2.4fr 1fr auto;
  grid-gap: 0 0.5rem;
  padding: 1rem;
  padding-top: 0.625rem;
  padding-bottom: 0;
  align-items: center;
}
.ticket-collapse-child-grid[data-v-22672f32] {
  grid-template-columns: 2rem repeat(6, 1.3fr) 2.4fr 1fr 0.7fr;
}
.purchaseId[data-v-22672f32] {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
  overflow: hidden;
  margin: auto;
}
.ticket-collapse-child-grid[data-v-22672f32] {
  padding: 1rem;
  padding-top: 2px;
  padding-bottom: 2px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  margin-top: 0.5rem;
}
.ticket-collapse-child-grid div[data-v-22672f32] {
  display: flex;
  align-items: center;
  position: relative;
}
.ticket-collapse-child-grid div span[data-v-22672f32] {
  display: flex;
  margin-left: 0.425rem;
}
.ticket-collapse-child-grid div span img[data-v-22672f32] {
  width: 1.185rem;
}
.ticket-collapse-child-values-grid[data-v-22672f32] {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 0.5rem;
}
.dark .ticket-collapse-child-grid[data-v-22672f32] {
  border-top: 1px solid;
}
.ticket-collapse[data-v-22672f32] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  padding: 1rem 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  color: #767475;
}
.dark .ticket-collapse[data-v-22672f32] {
  background: #3b435e;
  color: #f6f7ff;
}
.dark .ticket-collapse-child-grid[data-v-22672f32],
.dark .ticket-collapse-child-values-grid[data-v-22672f32] {
  border-color: #5a5d74;
}
.bold[data-v-22672f32] {
  font-weight: 500;
  color: #181818;
}
.dark .bold[data-v-22672f32] {
  color: #e2e2e2;
}
.input-container[data-v-22672f32] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.publish-checkbox-container[data-v-22672f32] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.input[data-v-22672f32] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.08) inset;
  border-radius: 0.4375rem;
  width: -webkit-max-content;
  width: max-content;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input input[data-v-22672f32] {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  width: 3.125rem;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #767475;
}
.dark .input[data-v-22672f32] {
  background: #353c53;
}
.dark .input input[data-v-22672f32] {
  color: #f6f7ff;
}

.header[data-v-c3490956] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-c3490956] {
  cursor: pointer;
}
.input[data-v-c3490956] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-c3490956] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-c3490956] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-c3490956] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-c3490956] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-c3490956] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-c3490956] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-c3490956] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-c3490956] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-c3490956] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-c3490956] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-c3490956] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-c3490956] {
  font-size: 1.125rem;
}
.readOnly[data-v-c3490956] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-c3490956] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-c3490956] {
  grid-column: span 2;
}
.breakup[data-v-c3490956] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-c3490956] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-c3490956] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-c3490956] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-c3490956] {
  justify-content: space-evenly;
}
.jc-sb[data-v-c3490956] {
  justify-content: space-between;
}
.btns[data-v-c3490956] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-c3490956] {
  padding: 0 1.25rem;
}
.pt-10[data-v-c3490956] {
  padding-top: 0.625rem;
}
.table-container[data-v-c3490956] {
  margin: 0 0.625rem;
}
.span-2[data-v-c3490956] {
  grid-column: 2/4;
}
.green[data-v-c3490956] {
  color: #12c519;
}
.mb-20[data-v-c3490956] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-c3490956] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-c3490956] {
  padding: 0 1.25rem;
}
.lg-header[data-v-c3490956] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-c3490956] {
  cursor: pointer;
}
.search-input-cm[data-v-c3490956] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-c3490956] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-c3490956],
.sm-item-grid[data-v-c3490956] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-c3490956] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-c3490956] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-c3490956] {
  background: #3b435e;
}
.sm-items[data-v-c3490956] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-c3490956] {
  background: #3b435e;
}
.col-grid[data-v-c3490956] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-c3490956] {
  font-weight: 500;
}
.list-item[data-v-c3490956] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-c3490956] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-c3490956] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-c3490956] {
  background: #3b435e;
}
.btn-container[data-v-c3490956] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-c3490956] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-c3490956] {
  background: #383f57;
}
.addTicketModal .header[data-v-c3490956] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-c3490956] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-c3490956] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-c3490956] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-c3490956] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-c3490956] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-c3490956] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-c3490956] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-c3490956] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-c3490956],
.add-ticket-details-grid .values-grid[data-v-c3490956] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-c3490956] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-c3490956] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-c3490956] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-c3490956] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-c3490956] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-c3490956] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-c3490956] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-c3490956] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-c3490956] {
  width: 100%;
}
.mapImg path.clickable[data-v-c3490956] {
  cursor: pointer;
}

.header[data-v-1d8b8bf0] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-1d8b8bf0] {
  cursor: pointer;
}
.input[data-v-1d8b8bf0] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-1d8b8bf0] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-1d8b8bf0] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-1d8b8bf0] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-1d8b8bf0] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-1d8b8bf0] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-1d8b8bf0] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-1d8b8bf0] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-1d8b8bf0] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-1d8b8bf0] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-1d8b8bf0] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-1d8b8bf0] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-1d8b8bf0] {
  font-size: 1.125rem;
}
.readOnly[data-v-1d8b8bf0] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-1d8b8bf0] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-1d8b8bf0] {
  grid-column: span 2;
}
.breakup[data-v-1d8b8bf0] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-1d8b8bf0] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-1d8b8bf0] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-1d8b8bf0] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-1d8b8bf0] {
  justify-content: space-evenly;
}
.jc-sb[data-v-1d8b8bf0] {
  justify-content: space-between;
}
.btns[data-v-1d8b8bf0] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-1d8b8bf0] {
  padding: 0 1.25rem;
}
.pt-10[data-v-1d8b8bf0] {
  padding-top: 0.625rem;
}
.table-container[data-v-1d8b8bf0] {
  margin: 0 0.625rem;
}
.span-2[data-v-1d8b8bf0] {
  grid-column: 2/4;
}
.green[data-v-1d8b8bf0] {
  color: #12c519;
}
.mb-20[data-v-1d8b8bf0] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-1d8b8bf0] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-1d8b8bf0] {
  padding: 0 1.25rem;
}
.lg-header[data-v-1d8b8bf0] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-1d8b8bf0] {
  cursor: pointer;
}
.search-input-cm[data-v-1d8b8bf0] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-1d8b8bf0] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-1d8b8bf0],
.sm-item-grid[data-v-1d8b8bf0] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-1d8b8bf0] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-1d8b8bf0] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-1d8b8bf0] {
  background: #3b435e;
}
.sm-items[data-v-1d8b8bf0] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-1d8b8bf0] {
  background: #3b435e;
}
.col-grid[data-v-1d8b8bf0] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-1d8b8bf0] {
  font-weight: 500;
}
.list-item[data-v-1d8b8bf0] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-1d8b8bf0] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-1d8b8bf0] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-1d8b8bf0] {
  background: #3b435e;
}
.btn-container[data-v-1d8b8bf0] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-1d8b8bf0] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-1d8b8bf0] {
  background: #383f57;
}
.addTicketModal .header[data-v-1d8b8bf0] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-1d8b8bf0] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-1d8b8bf0] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-1d8b8bf0] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-1d8b8bf0] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-1d8b8bf0] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-1d8b8bf0] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-1d8b8bf0] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-1d8b8bf0] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-1d8b8bf0],
.add-ticket-details-grid .values-grid[data-v-1d8b8bf0] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-1d8b8bf0] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-1d8b8bf0] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-1d8b8bf0] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-1d8b8bf0] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-1d8b8bf0] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-1d8b8bf0] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-1d8b8bf0] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-1d8b8bf0] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-1d8b8bf0] {
  width: 100%;
}
.mapImg path.clickable[data-v-1d8b8bf0] {
  cursor: pointer;
}

.header[data-v-fef75acb] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-fef75acb] {
  cursor: pointer;
}
.input[data-v-fef75acb] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-fef75acb] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-fef75acb] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-fef75acb] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-fef75acb] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-fef75acb] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-fef75acb] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-fef75acb] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-fef75acb] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-fef75acb] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-fef75acb] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-fef75acb] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-fef75acb] {
  font-size: 1.125rem;
}
.readOnly[data-v-fef75acb] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-fef75acb] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-fef75acb] {
  grid-column: span 2;
}
.breakup[data-v-fef75acb] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-fef75acb] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-fef75acb] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-fef75acb] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-fef75acb] {
  justify-content: space-evenly;
}
.jc-sb[data-v-fef75acb] {
  justify-content: space-between;
}
.btns[data-v-fef75acb] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-fef75acb] {
  padding: 0 1.25rem;
}
.pt-10[data-v-fef75acb] {
  padding-top: 0.625rem;
}
.table-container[data-v-fef75acb] {
  margin: 0 0.625rem;
}
.span-2[data-v-fef75acb] {
  grid-column: 2/4;
}
.green[data-v-fef75acb] {
  color: #12c519;
}
.mb-20[data-v-fef75acb] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-fef75acb] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-fef75acb] {
  padding: 0 1.25rem;
}
.lg-header[data-v-fef75acb] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-fef75acb] {
  cursor: pointer;
}
.search-input-cm[data-v-fef75acb] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-fef75acb] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-fef75acb],
.sm-item-grid[data-v-fef75acb] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-fef75acb] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-fef75acb] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-fef75acb] {
  background: #3b435e;
}
.sm-items[data-v-fef75acb] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-fef75acb] {
  background: #3b435e;
}
.col-grid[data-v-fef75acb] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-fef75acb] {
  font-weight: 500;
}
.list-item[data-v-fef75acb] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-fef75acb] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-fef75acb] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-fef75acb] {
  background: #3b435e;
}
.btn-container[data-v-fef75acb] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-fef75acb] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-fef75acb] {
  background: #383f57;
}
.addTicketModal .header[data-v-fef75acb] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-fef75acb] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-fef75acb] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-fef75acb] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-fef75acb] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-fef75acb] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-fef75acb] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-fef75acb] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-fef75acb] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-fef75acb],
.add-ticket-details-grid .values-grid[data-v-fef75acb] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-fef75acb] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-fef75acb] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-fef75acb] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-fef75acb] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-fef75acb] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-fef75acb] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-fef75acb] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-fef75acb] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-fef75acb] {
  width: 100%;
}
.mapImg path.clickable[data-v-fef75acb] {
  cursor: pointer;
}

.header[data-v-629d2a13] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-629d2a13] {
  cursor: pointer;
}
.input[data-v-629d2a13] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-629d2a13] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-629d2a13] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-629d2a13] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-629d2a13] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-629d2a13] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-629d2a13] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-629d2a13] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-629d2a13] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-629d2a13] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-629d2a13] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-629d2a13] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-629d2a13] {
  font-size: 1.125rem;
}
.readOnly[data-v-629d2a13] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-629d2a13] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-629d2a13] {
  grid-column: span 2;
}
.breakup[data-v-629d2a13] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-629d2a13] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-629d2a13] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-629d2a13] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-629d2a13] {
  justify-content: space-evenly;
}
.jc-sb[data-v-629d2a13] {
  justify-content: space-between;
}
.btns[data-v-629d2a13] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-629d2a13] {
  padding: 0 1.25rem;
}
.pt-10[data-v-629d2a13] {
  padding-top: 0.625rem;
}
.table-container[data-v-629d2a13] {
  margin: 0 0.625rem;
}
.span-2[data-v-629d2a13] {
  grid-column: 2/4;
}
.green[data-v-629d2a13] {
  color: #12c519;
}
.mb-20[data-v-629d2a13] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-629d2a13] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-629d2a13] {
  padding: 0 1.25rem;
}
.lg-header[data-v-629d2a13] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-629d2a13] {
  cursor: pointer;
}
.search-input-cm[data-v-629d2a13] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-629d2a13] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-629d2a13],
.sm-item-grid[data-v-629d2a13] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-629d2a13] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-629d2a13] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-629d2a13] {
  background: #3b435e;
}
.sm-items[data-v-629d2a13] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-629d2a13] {
  background: #3b435e;
}
.col-grid[data-v-629d2a13] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-629d2a13] {
  font-weight: 500;
}
.list-item[data-v-629d2a13] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-629d2a13] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-629d2a13] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-629d2a13] {
  background: #3b435e;
}
.btn-container[data-v-629d2a13] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-629d2a13] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-629d2a13] {
  background: #383f57;
}
.addTicketModal .header[data-v-629d2a13] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-629d2a13] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-629d2a13] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-629d2a13] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-629d2a13] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-629d2a13] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-629d2a13] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-629d2a13] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-629d2a13] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-629d2a13],
.add-ticket-details-grid .values-grid[data-v-629d2a13] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-629d2a13] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-629d2a13] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-629d2a13] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-629d2a13] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-629d2a13] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-629d2a13] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-629d2a13] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-629d2a13] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-629d2a13] {
  width: 100%;
}
.mapImg path.clickable[data-v-629d2a13] {
  cursor: pointer;
}

.header[data-v-ed62ac9b] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-ed62ac9b] {
  cursor: pointer;
}
.input[data-v-ed62ac9b] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-ed62ac9b] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-ed62ac9b] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-ed62ac9b] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-ed62ac9b] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-ed62ac9b] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-ed62ac9b] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-ed62ac9b] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-ed62ac9b] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-ed62ac9b] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-ed62ac9b] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-ed62ac9b] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-ed62ac9b] {
  font-size: 1.125rem;
}
.readOnly[data-v-ed62ac9b] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-ed62ac9b] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-ed62ac9b] {
  grid-column: span 2;
}
.breakup[data-v-ed62ac9b] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-ed62ac9b] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-ed62ac9b] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-ed62ac9b] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-ed62ac9b] {
  justify-content: space-evenly;
}
.jc-sb[data-v-ed62ac9b] {
  justify-content: space-between;
}
.btns[data-v-ed62ac9b] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-ed62ac9b] {
  padding: 0 1.25rem;
}
.pt-10[data-v-ed62ac9b] {
  padding-top: 0.625rem;
}
.table-container[data-v-ed62ac9b] {
  margin: 0 0.625rem;
}
.span-2[data-v-ed62ac9b] {
  grid-column: 2/4;
}
.green[data-v-ed62ac9b] {
  color: #12c519;
}
.mb-20[data-v-ed62ac9b] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-ed62ac9b] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-ed62ac9b] {
  padding: 0 1.25rem;
}
.lg-header[data-v-ed62ac9b] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-ed62ac9b] {
  cursor: pointer;
}
.search-input-cm[data-v-ed62ac9b] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-ed62ac9b] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-ed62ac9b],
.sm-item-grid[data-v-ed62ac9b] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-ed62ac9b] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-ed62ac9b] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-ed62ac9b] {
  background: #3b435e;
}
.sm-items[data-v-ed62ac9b] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-ed62ac9b] {
  background: #3b435e;
}
.col-grid[data-v-ed62ac9b] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-ed62ac9b] {
  font-weight: 500;
}
.list-item[data-v-ed62ac9b] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-ed62ac9b] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-ed62ac9b] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-ed62ac9b] {
  background: #3b435e;
}
.btn-container[data-v-ed62ac9b] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-ed62ac9b] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-ed62ac9b] {
  background: #383f57;
}
.addTicketModal .header[data-v-ed62ac9b] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-ed62ac9b] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-ed62ac9b] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-ed62ac9b] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-ed62ac9b] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-ed62ac9b] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-ed62ac9b] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-ed62ac9b] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-ed62ac9b] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-ed62ac9b],
.add-ticket-details-grid .values-grid[data-v-ed62ac9b] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-ed62ac9b] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-ed62ac9b] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-ed62ac9b] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-ed62ac9b] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-ed62ac9b] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-ed62ac9b] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-ed62ac9b] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-ed62ac9b] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-ed62ac9b] {
  width: 100%;
}
.mapImg path.clickable[data-v-ed62ac9b] {
  cursor: pointer;
}

.info-div[data-v-5d9c5ed8] {
  display: flex;
  align-items: center;
  height: 3.1875rem;
  width: 100%;
  padding: 1.375rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  background: #f6f7ff;
  border-radius: 0.4375rem;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
}
.dark .info-div[data-v-5d9c5ed8] {
  background: #3b435e;
  color: #5a5d74;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .whitetext[data-v-5d9c5ed8] {
  color: #f6f7ff;
}
.settings[data-v-5d9c5ed8] {
  padding: 0 0.9375rem 0 1.375rem;
}

.headersection[data-v-4ea0834d] {
  position: relative;
  padding: 0 1rem;
}
.headersection[data-v-4ea0834d]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-4ea0834d]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.smtext[data-v-4ea0834d] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.px-25[data-v-4ea0834d] {
  padding-left: 1.25rem;
  margin-top: 1.5625rem;
}
.label[data-v-4ea0834d] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.75rem;
}
.wrap[data-v-4ea0834d] {
  grid-gap: 1.5625rem 3rem;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(4, 14.0625rem);
}
.select-container[data-v-4ea0834d] {
  max-width: 14.0625rem;
  width: 100%;
  flex-shrink: 0;
}
.twogrid[data-v-4ea0834d] {
  display: grid;
  grid-template-columns: 1fr;
}
.two-grid[data-v-4ea0834d] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}
.end[data-v-4ea0834d] {
  justify-content: flex-end;
}
@media (max-width: 1700px) {
.wrap[data-v-4ea0834d] {
    grid-gap: 1.5rem 2.125rem;
}
}
.btncontainer[data-v-4ea0834d] {
  grid-column: span 4;
  margin-top: 1.875rem;
  justify-content: flex-end;
}
.w-100[data-v-4ea0834d] {
  width: 100%;
}
.abs[data-v-4ea0834d] {
  position: absolute;
  right: 2rem;
  top: -3rem;
}
.mt-custom[data-v-4ea0834d] {
  margin-top: 7rem;
}
.rel[data-v-4ea0834d] {
  position: relative;
}
.cp[data-v-4ea0834d] {
  cursor: pointer;
}
.visible[data-v-4ea0834d] {
  -webkit-transform-origin: center;
          transform-origin: center;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.px-15[data-v-4ea0834d] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgb(0 0 0 / 15%);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
  padding-right: 1rem;
}
.settings-container[data-v-4ea0834d] {
  max-height: 85vh;
  overflow-y: auto;
  padding-bottom: 1rem;
}
.dark .px-15[data-v-4ea0834d] {
  background: #353c53;
}
.pr-15[data-v-4ea0834d] {
  padding-right: 1.5rem;
}
.pad-5[data-v-4ea0834d] {
  margin-right: 15px;
}

.box[data-v-f9f67c41] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-f9f67c41] {
  background-color: #3b435e;
}
.smbox[data-v-f9f67c41] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-f9f67c41] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-f9f67c41] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-f9f67c41] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-f9f67c41] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-f9f67c41] {
  background-color: #464f6d;
}
.upload img[data-v-f9f67c41] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-f9f67c41] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-f9f67c41],
.smlabel img[data-v-f9f67c41] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-f9f67c41] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-f9f67c41] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-f9f67c41] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-f9f67c41] {
  object-fit: cover;
}
.equal[data-v-f9f67c41] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.grid[data-v-f9f67c41] {
  display: grid;
  grid-template-columns: 1fr 1fr 0.8fr;
  grid-gap: 1.875rem;
}
.grid[data-v-f9f67c41] {
  padding-right: 1.5rem;
  margin-bottom: 1rem;
}
.full[data-v-f9f67c41] {
  grid-column: 1/3;
}
.rect[data-v-f9f67c41] {
  height: 8.4375rem;
  margin-bottom: 0;
}
.smbox[data-v-f9f67c41] {
  max-height: none;
  grid-gap: 0rem 2rem;
}
.as-fe[data-v-f9f67c41] {
  align-self: flex-end;
}
.color-container input[data-v-f9f67c41] {
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.color-container[data-v-f9f67c41] {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
  padding-right: 0.9375rem;
  width: 14.0625rem;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border: none;
  outline: none;
  height: 3.1875rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  position: relative;
}

.box[data-v-5b31216c] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding: 1.25rem;
}
.dark .box[data-v-5b31216c] {
  background-color: #3b435e;
}
.smbox[data-v-5b31216c] {
  max-height: 13.9375rem;
  height: 100%;
}
.label[data-v-5b31216c] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.5625rem;
  margin-bottom: 0.75rem;
}
.lighttext[data-v-5b31216c] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #abafd1;
  margin-bottom: 1rem;
}
.twogrid[data-v-5b31216c] {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.0625rem;
}
.upload[data-v-5b31216c] {
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 3.4375rem;
  overflow: hidden;
}
.dark .upload[data-v-5b31216c] {
  background-color: #464f6d;
}
.upload img[data-v-5b31216c] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.smlabel[data-v-5b31216c] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.4375rem;
  padding-left: 0.5rem;
}
.smlabel svg[data-v-5b31216c],
.smlabel img[data-v-5b31216c] {
  margin-right: 0.625rem;
}
.smlabel img[data-v-5b31216c] {
  height: 1.125rem;
  width: 1.125rem;
}
.mb-13[data-v-5b31216c] {
  margin-bottom: 0.8125rem;
}
.rect[data-v-5b31216c] {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  max-height: 8.4375rem;
}
.rect img[data-v-5b31216c] {
  object-fit: cover;
}
.equal[data-v-5b31216c] {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.productinfo[data-v-4373f4be] {
  text-align: center;
  height: 100%;
  padding: 1.25rem 1.625rem 0 1.25rem;
  border-radius: 0.9375rem;
}
.categories[data-v-4373f4be] {
  padding: 1.25rem 1.625rem 0 1.25rem;
}
.headersection[data-v-4373f4be] {
  position: relative;
}
.headersection[data-v-4373f4be]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: -0.25rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-4373f4be]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.blur[data-v-4373f4be] {
  -webkit-filter: blur(0.375rem);
          filter: blur(0.375rem);
  -webkit-user-select: none;
          user-select: none;
}
.smtext[data-v-4373f4be] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.shoeimg[data-v-4373f4be] {
  width: 10.9375rem !important;
  height: 5.9375rem !important;
  object-fit: contain !important;
  border-radius: 0.9375rem !important;
  background-color: #fff;
  margin-top: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  -webkit-border-radius: 0.9375rem !important;
  -moz-border-radius: 0.9375rem !important;
  -ms-border-radius: 0.9375rem !important;
  -o-border-radius: 0.9375rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.mb-comparing[data-v-4373f4be] {
  margin-bottom: 0.5rem;
}
.bold[data-v-4373f4be] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.mt-10[data-v-4373f4be] {
  margin-top: 0.625rem;
}
.query[data-v-4373f4be] {
  margin-right: 0.3125rem;
}
.large .query[data-v-4373f4be],
.large .value[data-v-4373f4be] {
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.pricing[data-v-4373f4be] {
  text-align: center;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.red[data-v-4373f4be],
.green[data-v-4373f4be],
.blue[data-v-4373f4be] {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.brown[data-v-4373f4be] {
  background-color: #d07979;
}
.light-brown[data-v-4373f4be] {
  background-color: #d0a879;
}
.light-green[data-v-4373f4be] {
  background-color: #aad079;
}
.light-blue[data-v-4373f4be] {
  background-color: #79c1d0;
}
.dark-brown[data-v-4373f4be] {
  background-color: #b47467;
}
.pink[data-v-4373f4be] {
  background-color: #d079c8;
}
.transaction-history .red[data-v-4373f4be],
.transaction-history .green[data-v-4373f4be] {
  font-weight: 300 !important;
  font-size: 0.9375rem;
}
.site[data-v-4373f4be] {
  margin-bottom: 2rem;
}
.brand[data-v-4373f4be] {
  width: 6rem;
  height: 3.25rem;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  object-fit: contain;
  padding: 0.1875rem;
}
.bids[data-v-4373f4be] {
  margin-top: 1.0625rem;
  margin-bottom: 0.9375rem;
}
.al-st[data-v-4373f4be] {
  align-items: flex-start;
}
.new[data-v-4373f4be] {
  -webkit-backdrop-filter: blur(0.5625rem);
          backdrop-filter: blur(0.5625rem);
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  right: -0.3125rem;
  bottom: -0.3125rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  align-items: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.comparing[data-v-4373f4be] {
  position: relative;
  padding: 1.25rem 1.625rem 0 1.25rem;
  height: 100%;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.b-shadow[data-v-4373f4be] {
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
}
.value[data-v-4373f4be] {
  font-weight: 300;
  font-size: 0.9375rem;
}
.large .value[data-v-4373f4be] {
  font-weight: 400;
}
.categories-grid[data-v-4373f4be] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem 0.3125rem;
  text-align: center;
}
.blue[data-v-4373f4be] {
  background: #7983d0;
}
.lightblue[data-v-4373f4be] {
  background: #79c1d0;
}
.yellow[data-v-4373f4be] {
  background: #d0bd79;
}
.grey[data-v-4373f4be] {
  background: #abafd1;
}
.smbox[data-v-4373f4be] {
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  font-weight: 300 !important;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #ffffff !important;
  height: 2.5rem;
}
.cat[data-v-4373f4be] {
  min-width: 7rem;
}
.text-center[data-v-4373f4be] {
  text-align: center;
  margin-bottom: 1rem;
}
.categories-section[data-v-4373f4be] {
  margin-top: 2rem;
}
.swiper[data-v-4373f4be] {
  width: 100%;
  height: 100%;
}
.swiper-slide[data-v-4373f4be] {
  text-align: center;
  font-size: 1.125rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6875rem;
}
.swiper-slide img[data-v-4373f4be] {
  display: block;
  width: 100%;
  cursor: pointer;
  height: auto;
  object-fit: contain;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.trending[data-v-4373f4be] {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  border-radius: 0.9375rem;
  overflow: hidden;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.pl[data-v-4373f4be] {
  margin-left: 1.25rem;
  padding-top: 0.8125rem;
  padding-left: 0 !important  ;
}
.priceheader[data-v-4373f4be] {
  margin-left: 1.25rem;
  padding-right: 1.1875rem;
  padding-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.priceheader.price-history[data-v-4373f4be] {
  margin-bottom: 2.2rem;
}
.twogrid[data-v-4373f4be] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2.125rem;
}
.twogrid.smgrid[data-v-4373f4be] {
  padding: 0 !important;
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}
.shadow[data-v-4373f4be] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.875rem 1.375rem;
}
.dark .shadow[data-v-4373f4be] {
  background: #3b435e;
}
.shadow .bold[data-v-4373f4be] {
  margin-bottom: 0.3125rem;
}
.mt-10[data-v-4373f4be] {
  margin-top: 0.625rem !important;
}
.details[data-v-4373f4be] {
  margin-left: 1.5625rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.type[data-v-4373f4be] {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.cond[data-v-4373f4be] {
  margin-left: 0.625rem;
}
.headersgrid[data-v-4373f4be],
.item[data-v-4373f4be] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.1fr 0.8fr;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .item[data-v-4373f4be] {
  background-color: #3b435e;
}
.headersgrid div[data-v-4373f4be],
.item div[data-v-4373f4be] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.visible[data-v-4373f4be] {
  overflow: visible !important;
}
.transaction-history .item img[data-v-4373f4be] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.item[data-v-4373f4be] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 14px 17px;
  margin-bottom: 0.3125rem;
  font-weight: 300;
}
.headersgrid[data-v-4373f4be] {
  padding: 0 1.0625rem;
  font-weight: 500;
}
.transaction-history .headersgrid[data-v-4373f4be] {
  padding: 0 2rem;
}
.transaction-history .item[data-v-4373f4be] {
  padding: 0.375rem 0.625rem;
}
.transaction-history .headersgrid[data-v-4373f4be],
.transaction-history .item[data-v-4373f4be] {
  display: grid;
  grid-template-columns: 3.9375rem 1fr 0.4fr 0.6fr !important;
  grid-gap: 0.625rem !important;
}
.dark .transaction-history .item[data-v-4373f4be] {
  background-color: #3b435e;
}
.pricing .blue[data-v-4373f4be] {
  background: transparent !important;
}
.items[data-v-4373f4be] {
  max-height: 18.85rem;
  overflow-y: auto;
  padding: 0.75rem 1rem;
  margin-right: 0.375rem;
  padding-right: 0.375rem;
}
.transaction-history .items[data-v-4373f4be] {
  max-height: 41.35rem;
}
.transaction-history-owner .items[data-v-4373f4be] {
  max-height: 20rem !important;
}
.headerscotainer[data-v-4373f4be] {
  padding: 0 1rem;
}
.mt-15[data-v-4373f4be] {
  margin-bottom: 0.3rem;
}
.mt-30[data-v-4373f4be] {
  margin-bottom: 0.8rem;
}
.mt-0[data-v-4373f4be] {
  margin-top: 0 !important;
}
.mt-18[data-v-4373f4be] {
  margin-top: 1.125rem !important;
}
.home-categories[data-v-4373f4be] {
  padding: 1rem 1.125rem;
}
.home-categories .query[data-v-4373f4be],
.home-categories .value[data-v-4373f4be] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr-10[data-v-4373f4be] {
  padding-right: 4rem;
}
.staff-overviews .items[data-v-4373f4be] {
  max-height: 13rem;
}
.item img[data-v-4373f4be] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.total[data-v-4373f4be] {
  margin-left: -3.5rem;
  font-weight: 600;
}
.totaltext[data-v-4373f4be] {
  color: #abafd1;
}
.dark .totaltext[data-v-4373f4be] {
  color: #5a5d74;
}
.admin-items .headersgrid[data-v-4373f4be],
.admin-items .item[data-v-4373f4be] {
  grid-template-columns: 0.5fr 1fr 0.8fr 0.6fr !important;
}
.admin-items .item img[data-v-4373f4be] {
  margin-left: 0.3125rem;
}
.customer-details .items[data-v-4373f4be] {
  max-height: 15rem;
}
.customer-details .item[data-v-4373f4be] {
  grid-template-columns: 1fr 1fr !important;
}
.twogrid-section[data-v-4373f4be] {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.productinfo[data-v-ca37e576] {
  text-align: center;
  height: 100%;
  padding: 1.25rem 1.625rem 0 1.25rem;
  border-radius: 0.9375rem;
}
.categories[data-v-ca37e576] {
  padding: 1.25rem 1.625rem 0 1.25rem;
}
.headersection[data-v-ca37e576] {
  position: relative;
}
.headersection[data-v-ca37e576]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: -0.25rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-ca37e576]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.blur[data-v-ca37e576] {
  -webkit-filter: blur(0.375rem);
          filter: blur(0.375rem);
  -webkit-user-select: none;
          user-select: none;
}
.smtext[data-v-ca37e576] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.shoeimg[data-v-ca37e576] {
  width: 10.9375rem !important;
  height: 5.9375rem !important;
  object-fit: contain !important;
  border-radius: 0.9375rem !important;
  background-color: #fff;
  margin-top: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  -webkit-border-radius: 0.9375rem !important;
  -moz-border-radius: 0.9375rem !important;
  -ms-border-radius: 0.9375rem !important;
  -o-border-radius: 0.9375rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.mb-comparing[data-v-ca37e576] {
  margin-bottom: 0.5rem;
}
.bold[data-v-ca37e576] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.mt-10[data-v-ca37e576] {
  margin-top: 0.625rem;
}
.query[data-v-ca37e576] {
  margin-right: 0.3125rem;
}
.large .query[data-v-ca37e576],
.large .value[data-v-ca37e576] {
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.pricing[data-v-ca37e576] {
  text-align: center;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}
.red[data-v-ca37e576],
.green[data-v-ca37e576],
.blue[data-v-ca37e576] {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.brown[data-v-ca37e576] {
  background-color: #d07979;
}
.light-brown[data-v-ca37e576] {
  background-color: #d0a879;
}
.light-green[data-v-ca37e576] {
  background-color: #aad079;
}
.light-blue[data-v-ca37e576] {
  background-color: #79c1d0;
}
.dark-brown[data-v-ca37e576] {
  background-color: #b47467;
}
.pink[data-v-ca37e576] {
  background-color: #d079c8;
}
.transaction-history .red[data-v-ca37e576],
.transaction-history .green[data-v-ca37e576] {
  font-weight: 300 !important;
  font-size: 0.9375rem;
}
.site[data-v-ca37e576] {
  margin-bottom: 2rem;
}
.brand[data-v-ca37e576] {
  width: 6rem;
  height: 3.25rem;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  object-fit: contain;
  padding: 0.1875rem;
}
.bids[data-v-ca37e576] {
  margin-top: 1.0625rem;
  margin-bottom: 0.9375rem;
}
.al-st[data-v-ca37e576] {
  align-items: flex-start;
}
.new[data-v-ca37e576] {
  -webkit-backdrop-filter: blur(0.5625rem);
          backdrop-filter: blur(0.5625rem);
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  right: -0.3125rem;
  bottom: -0.3125rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  align-items: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.comparing[data-v-ca37e576] {
  position: relative;
  padding: 1.25rem 1.625rem 0 1.25rem;
  height: 100%;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.b-shadow[data-v-ca37e576] {
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
}
.value[data-v-ca37e576] {
  font-weight: 300;
  font-size: 0.9375rem;
}
.large .value[data-v-ca37e576] {
  font-weight: 400;
}
.categories-grid[data-v-ca37e576] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem 0.3125rem;
  text-align: center;
}
.blue[data-v-ca37e576] {
  background: #7983d0;
}
.lightblue[data-v-ca37e576] {
  background: #79c1d0;
}
.yellow[data-v-ca37e576] {
  background: #d0bd79;
}
.grey[data-v-ca37e576] {
  background: #abafd1;
}
.smbox[data-v-ca37e576] {
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  font-weight: 300 !important;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #ffffff !important;
  height: 2.5rem;
}
.cat[data-v-ca37e576] {
  min-width: 7rem;
}
.text-center[data-v-ca37e576] {
  text-align: center;
  margin-bottom: 1rem;
}
.categories-section[data-v-ca37e576] {
  margin-top: 2rem;
}
.swiper[data-v-ca37e576] {
  width: 100%;
  height: 100%;
}
.swiper-slide[data-v-ca37e576] {
  text-align: center;
  font-size: 1.125rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6875rem;
}
.swiper-slide img[data-v-ca37e576] {
  display: block;
  width: 100%;
  cursor: pointer;
  height: auto;
  object-fit: contain;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.trending[data-v-ca37e576] {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  border-radius: 0.9375rem;
  overflow: hidden;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.pl[data-v-ca37e576] {
  margin-left: 1.25rem;
  padding-top: 0.8125rem;
  padding-left: 0 !important  ;
}
.priceheader[data-v-ca37e576] {
  margin-left: 1.25rem;
  padding-right: 1.1875rem;
  padding-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.priceheader.price-history[data-v-ca37e576] {
  margin-bottom: 2.2rem;
}
.twogrid[data-v-ca37e576] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2.125rem;
}
.twogrid.smgrid[data-v-ca37e576] {
  padding: 0 !important;
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}
.shadow[data-v-ca37e576] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding: 0.875rem 1.375rem;
}
.dark .shadow[data-v-ca37e576] {
  background: #3b435e;
}
.shadow .bold[data-v-ca37e576] {
  margin-bottom: 0.3125rem;
}
.mt-10[data-v-ca37e576] {
  margin-top: 0.625rem !important;
}
.details[data-v-ca37e576] {
  margin-left: 1.5625rem;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.type[data-v-ca37e576] {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.cond[data-v-ca37e576] {
  margin-left: 0.625rem;
}
.headersgrid[data-v-ca37e576],
.item[data-v-ca37e576] {
  display: grid;
  grid-template-columns: 1fr 1fr 1.1fr 0.8fr;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .item[data-v-ca37e576] {
  background-color: #3b435e;
}
.headersgrid div[data-v-ca37e576],
.item div[data-v-ca37e576] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.visible[data-v-ca37e576] {
  overflow: visible !important;
}
.transaction-history .item img[data-v-ca37e576] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.item[data-v-ca37e576] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 14px 17px;
  margin-bottom: 0.3125rem;
  font-weight: 300;
}
.headersgrid[data-v-ca37e576] {
  padding: 0 1.0625rem;
  font-weight: 500;
}
.transaction-history .headersgrid[data-v-ca37e576] {
  padding: 0 2rem;
}
.transaction-history .item[data-v-ca37e576] {
  padding: 0.375rem 0.625rem;
}
.transaction-history .headersgrid[data-v-ca37e576],
.transaction-history .item[data-v-ca37e576] {
  display: grid;
  grid-template-columns: 3.9375rem 1fr 0.4fr 0.6fr !important;
  grid-gap: 0.625rem !important;
}
.dark .transaction-history .item[data-v-ca37e576] {
  background-color: #3b435e;
}
.pricing .blue[data-v-ca37e576] {
  background: transparent !important;
}
.items[data-v-ca37e576] {
  max-height: 18.85rem;
  overflow-y: auto;
  padding: 0.75rem 1rem;
  margin-right: 0.375rem;
  padding-right: 0.375rem;
}
.transaction-history .items[data-v-ca37e576] {
  max-height: 41.35rem;
}
.transaction-history-owner .items[data-v-ca37e576] {
  max-height: 20rem !important;
}
.headerscotainer[data-v-ca37e576] {
  padding: 0 1rem;
}
.mt-15[data-v-ca37e576] {
  margin-bottom: 0.3rem;
}
.mt-30[data-v-ca37e576] {
  margin-bottom: 0.8rem;
}
.mt-0[data-v-ca37e576] {
  margin-top: 0 !important;
}
.mt-18[data-v-ca37e576] {
  margin-top: 1.125rem !important;
}
.home-categories[data-v-ca37e576] {
  padding: 1rem 1.125rem;
}
.home-categories .query[data-v-ca37e576],
.home-categories .value[data-v-ca37e576] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr-10[data-v-ca37e576] {
  padding-right: 4rem;
}
.staff-overviews .items[data-v-ca37e576] {
  max-height: 13rem;
}
.item img[data-v-ca37e576] {
  height: 2.25rem;
  border-radius: 0.4375rem;
  width: 4rem;
  object-fit: contain;
  background-color: #fff;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.total[data-v-ca37e576] {
  margin-left: -3.5rem;
  font-weight: 600;
}
.totaltext[data-v-ca37e576] {
  color: #abafd1;
}
.dark .totaltext[data-v-ca37e576] {
  color: #5a5d74;
}
.admin-items .headersgrid[data-v-ca37e576],
.admin-items .item[data-v-ca37e576] {
  grid-template-columns: 0.5fr 1fr 0.8fr 0.6fr !important;
}
.admin-items .item img[data-v-ca37e576] {
  margin-left: 0.3125rem;
}
.customer-details .items[data-v-ca37e576] {
  max-height: 15rem;
}
.customer-details .item[data-v-ca37e576] {
  grid-template-columns: 1fr 1fr !important;
}
.twogrid-section[data-v-ca37e576] {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.tooltip[data-v-87907b5e] {
  border-radius: 0.25rem;
  background: #fff;
  padding: 1rem;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(255, 255, 255, 0.25);
  text-align: center;
  color: #26313c;
}
.dark .tooltip[data-v-87907b5e] {
  background-color: #26313c;
  color: #fff;
  box-shadow: 0.9375rem 1.875rem 2.5rem 0.3125rem rgba(0, 0, 0, 0.25);
}

.items[data-v-e3e75ef8] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23.6875rem, 1fr));
  grid-gap: 2rem;
}
.item[data-v-e3e75ef8] {
  min-height: 24.5625rem;
}
.items-container[data-v-e3e75ef8] {
  padding-top: 0.5rem;
  max-height: 85vh;
  overflow-x: auto;
  padding-bottom: 2rem;
}
.threegrid[data-v-e3e75ef8] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 19.5625rem 27.5rem;
  grid-gap: 1.6rem;
}
.admingrid.threegrid[data-v-e3e75ef8] {
  grid-gap: 1rem;
}
.box[data-v-e3e75ef8] {
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.span-2[data-v-e3e75ef8] {
  grid-column: span 2;
}
.twogrid[data-v-e3e75ef8] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.left[data-v-e3e75ef8] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.8125rem;
  grid-template-rows: repeat(3, 22.7625rem);
  padding-right: 1rem;
}
.left.admin[data-v-e3e75ef8] {
  grid-template-rows: repeat(3, 21.5625rem) !important;
}
.leftcontainer[data-v-e3e75ef8] {
  max-height: min(87vh, 61rem);
  overflow-y: auto;
  grid-row: 1/4;
}
.right[data-v-e3e75ef8] {
  grid-row: 1/3;
  display: grid;
  grid-gap: 1.8125rem;
  grid-template-rows: 19.5625rem;
}
.transaction[data-v-e3e75ef8] {
  grid-row: 1/3;
}
.pt-10[data-v-e3e75ef8] {
  padding-top: 0.625rem;
}
.pb-10[data-v-e3e75ef8] {
  padding-bottom: 0.625rem;
}
.mb-0[data-v-e3e75ef8] {
  margin-bottom: 0 !important;
}
.mb-30[data-v-e3e75ef8] {
  margin-bottom: 1.875rem !important;
}
.col-span[data-v-e3e75ef8] {
  grid-row: 1/3;
}
.overflow[data-v-e3e75ef8] {
  max-height: 25.75rem;
  overflow-y: auto;
  margin-right: 1rem;
}
.jc-fe[data-v-e3e75ef8] {
  justify-content: flex-end;
}
.graph-download[data-v-e3e75ef8] {
  cursor: pointer;
  height: 1.375rem;
}
.store-info.threegrid[data-v-e3e75ef8] {
  grid-template-rows: 19.5625rem 26.25rem;
}

.headersection[data-v-e3e75ef8] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-e3e75ef8]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-e3e75ef8]::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.green[data-v-e3e75ef8] {
  color: #12c519;
}
.black[data-v-e3e75ef8] {
  margin-right: 0.3125rem;
  color: #323a52 !important;
}
.dark .black[data-v-e3e75ef8] {
  color: #f6f7ff !important;
}
.itemssold[data-v-e3e75ef8] {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.35rem;
  margin-left: 0.625rem;
}
.al-fe[data-v-e3e75ef8] {
  align-items: flex-end;
}
.px-20[data-v-e3e75ef8] {
  padding: 0 1.25rem;
}
.headers[data-v-e3e75ef8] {
  margin-bottom: 0.875rem;
}
.bold[data-v-e3e75ef8] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}
.transaction-history[data-v-e3e75ef8] {
  grid-row: span 2;
}
.type[data-v-e3e75ef8]:not(:last-child) {
  margin-right: 1.5625rem;
}
.type[data-v-e3e75ef8] {
  color: #abafd1;
  cursor: pointer;
}
.type.active[data-v-e3e75ef8] {
  color: #323a52;
}
.dark .type[data-v-e3e75ef8] {
  color: #5a5d74;
}
.dark .type.active[data-v-e3e75ef8] {
  color: #f6f7ff;
}

.productWrapper[data-v-b3d65b6c] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-radius: 0.625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding-bottom: 1.0625rem;
  height: 100%;
}
.dark .productWrapper[data-v-b3d65b6c] {
  background: #353c53;
}
.categoryBox[data-v-b3d65b6c] {
  background-color: #7983d0;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  text-align: center;
  color: #f6f7ff;
}
.dark .item[data-v-b3d65b6c] {
  color: #3b435e;
}
.shoeImgnew[data-v-b3d65b6c] {
  margin-top: 0.625rem;
  height: 3.9375rem;
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  -webkit-filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  /* width: 7.375rem;
  object-fit: fill; */
}
.prodTitle1[data-v-b3d65b6c],
.heading[data-v-b3d65b6c] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
}
.prodTitle1[data-v-b3d65b6c] {
  margin-bottom: 0.3125rem;
}
.heading[data-v-b3d65b6c] {
  font-weight: 400;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
}
.sideDiv[data-v-b3d65b6c] {
  text-align: start;
  width: 100%;

  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  padding: 0.625rem 1.25rem;
  margin-bottom: 2.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.newstyle span[data-v-b3d65b6c],
.size span[data-v-b3d65b6c],
.consignment span[data-v-b3d65b6c] {
  font-weight: 600;
  margin-right: 0.625rem;
}
.newstyle[data-v-b3d65b6c],
.size[data-v-b3d65b6c],
.consignment[data-v-b3d65b6c] {
  margin-bottom: 0.3pc;
}
.whiteBoxWrapnew[data-v-b3d65b6c] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem;
}
.barcode[data-v-b3d65b6c] {
  width: 100%;
}
.sneakers[data-v-b3d65b6c] {
  background-color: #7983d0;  /* blue */
}
.streetwear[data-v-b3d65b6c] {
  background-color: #79c1d0;  /* green */
}
.collectibles[data-v-b3d65b6c] {
  background-color: #d07979;  /* brown */
}
.arts[data-v-b3d65b6c] {
  background-color: #d079c8;  /* pink */
}
.electronics[data-v-b3d65b6c] {
  background-color: #d0a879;  /* orange */
}
.watches[data-v-b3d65b6c] {
  background-color: #aad079;  /* lightgreen */
}

.productWrapper[data-v-403f9187] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-radius: 0.625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  padding-bottom: 1.0625rem;
  height: 100%;
}
.dark .productWrapper[data-v-403f9187] {
  background: #353c53;
}
.categoryBox[data-v-403f9187] {
  background-color: #7983d0;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  text-align: center;
  color: #f6f7ff;
}
.dark .item[data-v-403f9187] {
  color: #3b435e;
}
.shoeImgnew[data-v-403f9187] {
  margin-top: 0.625rem;
  height: 3.9375rem;
  border-radius: 0.4375rem;
  margin-bottom: 0.625rem;
  -webkit-filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0rem 0rem 0.625rem rgba(0, 0, 0, 0.15));
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  /* width: 7.375rem;
  object-fit: fill; */
}
.prodTitle1[data-v-403f9187],
.heading[data-v-403f9187] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
}
.prodTitle1[data-v-403f9187] {
  margin-bottom: 0.3125rem;
}
.heading[data-v-403f9187] {
  font-weight: 400;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
}
.sideDiv[data-v-403f9187] {
  text-align: start;
  width: 100%;

  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  padding: 0.625rem 1.25rem;
  margin-bottom: 2.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
}
.newstyle span[data-v-403f9187],
.size span[data-v-403f9187],
.consignment span[data-v-403f9187] {
  font-weight: 600;
  margin-right: 0.625rem;
}
.newstyle[data-v-403f9187],
.size[data-v-403f9187],
.consignment[data-v-403f9187] {
  margin-bottom: 0.3pc;
}
.whiteBoxWrapnew[data-v-403f9187] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem;
}
.barcode[data-v-403f9187] {
  width: 100%;
}
.sneakers[data-v-403f9187] {
  background-color: #7983d0;  /* blue */
}
.streetwear[data-v-403f9187] {
  background-color: #79c1d0;  /* green */
}
.collectibles[data-v-403f9187] {
  background-color: #d07979;  /* brown */
}
.arts[data-v-403f9187] {
  background-color: #d079c8;  /* pink */
}
.electronics[data-v-403f9187] {
  background-color: #d0a879;  /* orange */
}
.watches[data-v-403f9187] {
  background-color: #aad079;  /* lightgreen */
}

.left-img[data-v-0d5d449f] {
  position: absolute;
  left: 0;
  top: 1rem;
  width: 35vw;
}
.right-img[data-v-0d5d449f] {
  position: absolute;
  bottom: 0;
  right: 1rem;
  width: 35vw;
}
.container[data-v-0d5d449f] {
  position: relative;
  z-index: 10;
  max-width: 32.5rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
.login-page[data-v-0d5d449f] {
  min-height: 100vh;
  z-index: 100;
  background-color: #f6f7ff;
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}
.dark .login-page[data-v-0d5d449f] {
  background-color: #262c41;
}
.bg[data-v-0d5d449f] {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #f6f7ff;
}
.dark .bg[data-v-0d5d449f] {
  background-color: #353c53;
}
.label[data-v-0d5d449f] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  margin-bottom: 1.5625rem;
}
.or[data-v-0d5d449f] {
  margin-top: 2.25rem;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  text-align: center;
}
.mt[data-v-0d5d449f] {
  margin-top: 3.3125rem;
}
.logo[data-v-0d5d449f] {
  max-width: 22.75rem;
  margin: 0 auto;
  margin-bottom: 3.125rem;
}
.smtext[data-v-0d5d449f] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.checkbox[data-v-0d5d449f] {
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
}
.login-btn[data-v-0d5d449f] {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.8125rem;

  cursor: pointer;
  height: 5.25rem;
  width: 16.25rem;
  margin: 3.75rem auto 0 auto;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.dark .login-btn[data-v-0d5d449f] {
  background: #353c53;
}
.login-btn[data-v-0d5d449f]:hover {
  box-shadow: 0rem 0rem 0.875rem rgba(0, 0, 0, 0.25);
}
.login-btn svg[data-v-0d5d449f] {
  margin-right: 0.625rem;
}
.abs-center[data-v-0d5d449f] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.logout-page[data-v-0d5d449f] {
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}
.dark .logout-page[data-v-0d5d449f] {
  background-color: #262c41;
}
.back-btn[data-v-0d5d449f] {
  cursor: pointer;
  position: relative;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
  margin-top: 1rem;
  font-size: 1.2rem;
}
.back-btn[data-v-0d5d449f]::after {
  position: absolute;
  bottom: -0.05rem;
  height: 1px;
  width: 100%;
  left: 0;
  content: "";
  background-color: #000;
}
.logged[data-v-0d5d449f] {
  font-size: 2rem;
}
.bold[data-v-0d5d449f] {
  cursor: pointer;
  text-decoration: underline;
}
.dn[data-v-0d5d449f] {
  display: none;
}
.left-img[data-v-accdef90] {
  position: absolute;
  left: 0;
  top: 1rem;
  width: 35vw;
}
.right-img[data-v-accdef90] {
  position: absolute;
  bottom: 0;
  right: 1rem;
  width: 35vw;
}
.container[data-v-accdef90] {
  position: relative;
  z-index: 10;
  max-width: 32.5rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
.login-page[data-v-accdef90] {
  min-height: 100vh;
  z-index: 100;
  background-color: #f6f7ff;
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}
.dark .login-page[data-v-accdef90] {
  background-color: #262c41;
}
.bg[data-v-accdef90] {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #f6f7ff;
}
.dark .bg[data-v-accdef90] {
  background-color: #353c53;
}
.label[data-v-accdef90] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  margin-bottom: 1.5625rem;
}
.or[data-v-accdef90] {
  margin-top: 2.25rem;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  text-align: center;
}
.mt[data-v-accdef90] {
  margin-top: 3.3125rem;
}
.logo[data-v-accdef90] {
  max-width: 22.75rem;
  margin: 0 auto;
  margin-bottom: 3.125rem;
}
.smtext[data-v-accdef90] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.checkbox[data-v-accdef90] {
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
}
.login-btn[data-v-accdef90] {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.8125rem;

  cursor: pointer;
  height: 5.25rem;
  width: 16.25rem;
  margin: 3.75rem auto 0 auto;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.dark .login-btn[data-v-accdef90] {
  background: #353c53;
}
.login-btn[data-v-accdef90]:hover {
  box-shadow: 0rem 0rem 0.875rem rgba(0, 0, 0, 0.25);
}
.login-btn svg[data-v-accdef90] {
  margin-right: 0.625rem;
}
.abs-center[data-v-accdef90] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.logout-page[data-v-accdef90] {
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}
.dark .logout-page[data-v-accdef90] {
  background-color: #262c41;
}
.back-btn[data-v-accdef90] {
  cursor: pointer;
  position: relative;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
  margin-top: 1rem;
  font-size: 1.2rem;
}
.back-btn[data-v-accdef90]::after {
  position: absolute;
  bottom: -0.05rem;
  height: 1px;
  width: 100%;
  left: 0;
  content: "";
  background-color: #000;
}
.logged[data-v-accdef90] {
  font-size: 2rem;
}
.bold[data-v-accdef90] {
  cursor: pointer;
  text-decoration: underline;
}
.dn[data-v-accdef90] {
  display: none;
}
.preview[data-v-63ab0043] {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}
.header[data-v-63ab0043] {
  padding: 1.25rem 2.25rem 0 1.25rem;
}
.sm_header[data-v-63ab0043] {
  top: 1.25rem;
  font-weight: 400;
  font-size: 1.125rem;
}
.order_id[data-v-63ab0043] {
  font-size: 1rem;
  font-weight: 300;
}
.cart[data-v-63ab0043] {
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-basis: 49%;
}
.items[data-v-63ab0043] {
  padding: 0 0 0 1.25rem;
  padding-right: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 0;
  overflow-y: auto;
  padding-top: 0.5rem;
}
.item[data-v-63ab0043] {
  display: grid;
  grid-template-columns: 1fr 5rem;
  align-items: center;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 0.625rem 1.25rem 0.625rem 0.625rem;
  margin-bottom: 0.3125rem;
}
.btns svg[data-v-63ab0043] {
  cursor: pointer;
}
.amt[data-v-63ab0043] {
  margin: 0 1.25rem;
}
.dark .item[data-v-63ab0043] {
  background: #353c53;
}
.info[data-v-63ab0043] {
  font-weight: 300;
  font-size: 0.875rem;
}
.sub_total[data-v-63ab0043] {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  padding: 1rem;
  border-top: 1px solid #abafd1;
}
.bold[data-v-63ab0043] {
  font-weight: 600;
}
.total[data-v-63ab0043] {
  font-size: 1.5rem;
}
.details[data-v-63ab0043] {
  padding: 0 0 0 1.25rem;
}
.purple[data-v-63ab0043] {
  color: #767bfa;
  font-weight: 500;
}
.suggestion[data-v-63ab0043] {
  display: grid;
  flex-basis: 49%;
  grid-template-rows: auto auto 1fr;
}
.horizontal_scroll[data-v-63ab0043] {
  display: flex;
  min-width: 0;
  overflow-x: auto;
  flex-grow: 0;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
}
.horizontal_scroll[data-v-63ab0043]::-webkit-scrollbar {
  height: 0.1875rem;
  cursor: pointer;
}
.rec_item[data-v-63ab0043] {
  box-shadow: 0rem 0rem 0.4375rem rgba(0, 0, 0, 0.15);
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  height: 15.625rem;
  min-width: 23.75rem;
  justify-content: space-between;
  padding: 1rem;
  padding-left: 0rem;
}
.rec_item[data-v-63ab0043]:not(:first-child) {
  margin-left: 1.25rem;
}
.dark .rec_item[data-v-63ab0043] {
  background: #353c53;
}
.shoeimg[data-v-63ab0043] {
  height: 9.0625rem;
}
.shoename[data-v-63ab0043] {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
  line-height: 1.875rem;
}
.svg[data-v-63ab0043] {
  float: right;
  margin-top: 1rem;
}
.model[data-v-63ab0043] {
  font-size: 1.125rem;
  font-weight: 300;
  max-width: 18ch;
}
.more[data-v-63ab0043] {
  padding-right: 1rem;
}
.more_container[data-v-63ab0043] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 1rem;
}
.dark .more_container[data-v-63ab0043] {
  background: #353c53;
}
.showitem[data-v-63ab0043] {
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
  text-align: center;
}
.item_heaeder[data-v-63ab0043] {
  margin-bottom: 0.75rem;
}
.custom[data-v-63ab0043] {
  padding: 0.5rem 0;
}
.pl-0[data-v-63ab0043] {
  padding-left: 0 !important;
}
.border[data-v-63ab0043] {
  width: 100%;
  border: 0.125rem solid #767bfa;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  margin: 0.75rem 0;
}
.w-100[data-v-63ab0043] {
  width: 100%;
  padding: 0 1rem;
}
.jc-fe[data-v-63ab0043] {
  justify-content: flex-end;
}
.text[data-v-63ab0043] {
  margin-left: 0.625rem;
}

.summary[data-v-ff8668e8] {
 display: grid;
 grid-gap: 1.875rem 2.5rem;
 grid-template-rows: 19.5625rem 26.25rem;
 grid-template-columns: 1fr 1fr 1fr;
}
.headersection[data-v-ff8668e8] {
 position: relative;
 font-weight: 600;
 font-size: 0.9375rem;
 line-height: 1.375rem;
 margin-bottom: 0.625rem;
 padding: 0.8125rem 1.25rem;
 padding-bottom: 0;
}
.headersection[data-v-ff8668e8]::before {
 position: absolute;
 content: "";
 height: 0.0625rem;
 width: 7.3125rem;
 bottom: -0.25rem;
 left: 1rem;
 background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBndExGYAgFATgeyQhgjKJGw2UAhpFk2gCNQKTOqkNaAJSgIH3LzfcdN+Ram1ERgjBfPfhUKhqOimE8OCZn3ObwKC09SBIFIoxuvfaDRhq3a9ENIAh7SQwpM/GFEuu/wE/Yhq/Vk5rRAAAAABJRU5ErkJggg==);
 background-size: contain;
 background-repeat: no-repeat;
}
.dark .headersection[data-v-ff8668e8]::before {
 background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAABCAYAAADgt+FrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABdSURBVHgBnc/BEYAgDATAC76UoQdaoRItRSvRUujEJsB81BgL4EH2ezN3c3SxCBqeGykEyujELFFLTxjIi817WmGgX/7NiH55GinBoFY5yGGGgW4SDEqRxQ3YW/kHOgEb7ee85F4AAAAASUVORK5CYII=);
}
.box[data-v-ff8668e8] {
 background: #f6f7ff;
 box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
 border-radius: 0.9375rem;
}
.receipt-container[data-v-ff8668e8] {
 padding: 0.8125rem 0;
}
.dark .box[data-v-ff8668e8] {
 background: #353c53;
}
.cashout[data-v-ff8668e8] {
 grid-column: 3/4;
 grid-row: 1/3;
}
.bold[data-v-ff8668e8] {
 font-weight: 600;
 font-size: 1.125rem;
 line-height: 1.6875rem;
 color: #abafd1;
}
.value[data-v-ff8668e8] {
 font-weight: 600;
 font-size: 1.125rem;
 line-height: 1.6875rem;

 margin-left: 0.3125rem;
}
.span2[data-v-ff8668e8] {
 grid-column: 1/3;
}
.total[data-v-ff8668e8] {
 padding-right: 0.875rem;
}
.summary-table[data-v-ff8668e8] {
 background: #f6f7ff;
 box-shadow: 0rem 0rem 0.6875rem rgb(0 0 0 / 15%);
 border-radius: 0.9375rem;
 padding: 0.8125rem 0;
}
.dark .summary-table[data-v-ff8668e8] {
 background: #353c53;
}
.purple[data-v-ff8668e8] {
 font-weight: 500;
 color: #767bfa;
}
.query[data-v-ff8668e8]:not(:last-child) {
 margin-right: 2.5rem;
}
.query[data-v-ff8668e8] {
 font-size: 0.9375rem;
 line-height: 1.375rem;
}
.smtext[data-v-ff8668e8] {
 font-weight: 600;
 font-size: 0.9375rem;
 line-height: 1.375rem;

 padding-left: 0.25rem;
}
.queryvalue[data-v-ff8668e8] {
 font-weight: 300;

 margin-left: 0.625rem;
}
.green[data-v-ff8668e8] {
 color: #12c519;
}
.product-items[data-v-ff8668e8] {
 display: grid;
 grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
 grid-gap: 0.625rem;
}
.product-container[data-v-ff8668e8] {
 padding-top: 0.625rem;
 max-height: calc(100vh - 10rem);
 overflow-y: scroll;
 padding-right: 1.25rem !important;
 margin-right: 1.25rem;
 padding-bottom: 1.25rem;
}
.pos[data-v-ff8668e8] {
 display: grid;
 grid-template-columns: 1fr 42.375rem;
 min-height: 50rem;
}
.cart[data-v-ff8668e8] {
 background: #f6f7ff;
 box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
 border-radius: 0.9375rem;
 display: grid;
 grid-template-rows: auto 1fr auto;
}
.dark .cart[data-v-ff8668e8] {
 background: #353c53;
}
.pt-0[data-v-ff8668e8] {
 padding-top: 0 !important;
}
.note[data-v-ff8668e8] {
 font-weight: 300;
 font-size: 0.8125rem;
 line-height: 1.25rem;
 text-align: center;
 margin-left: 0.425rem;
 color: #767bfa;
}
.headersection[data-v-ff8668e8] {
 display: flex;
}
.headersection > span[data-v-ff8668e8]:first-child {
 white-space: nowrap;
}
.amt[data-v-ff8668e8] {
 font-weight: 300;
 font-size: 1.875rem;
 line-height: 2.8125rem;
}
.subText[data-v-ff8668e8] {
 font-weight: 400;
 font-size: 0.9375rem;
 line-height: 1.375rem;
 color: #323a52;
}
.dark .subText[data-v-ff8668e8] {
 color: #fff;
}
.btn[data-v-ff8668e8] {
 font-weight: 400;
 font-size: 0.9375rem;
 line-height: 1.125rem;
 color: #12c519;
 background: #f6f7ff;
 box-shadow: 0rem 0rem 0.125rem rgba(0, 0, 0, 0.35);
 border-radius: 0.3125rem;
 width: -webkit-max-content;
 width: max-content;
 max-width: 12.5rem;
 display: flex;
 align-items: center;
 width: 100%;
 justify-content: center;
 cursor: pointer;
 margin: auto;
 margin-top: 1.6875rem;
 height: 3.125rem;
 transition: all 0.2s ease;
 -webkit-transition: all 0.2s ease;
 -moz-transition: all 0.2s ease;
 -ms-transition: all 0.2s ease;
 -o-transition: all 0.2s ease;
}
.walletDetails[data-v-ff8668e8] {
 padding: 0 3rem;
 padding-top: 0.625rem;
}
.btn[data-v-ff8668e8]:hover {
 background: #e5e6f2;
}
@media (max-width: 115.625rem) {
.pos[data-v-ff8668e8] {
  grid-template-columns: 1fr 35rem !important;
}
}
.black[data-v-ff8668e8] {
 margin-right: 0.3125rem;
 color: #323a52 !important;
}
.dark .black[data-v-ff8668e8] {
 color: #f6f7ff !important;
}
.itemssold[data-v-ff8668e8] {
 font-weight: 300;
 font-size: 0.75rem;
 line-height: 1.35rem;
 margin-left: 0.625rem;
}
.al-fe[data-v-ff8668e8] {
 align-items: flex-end;
}
.px-20[data-v-ff8668e8] {
 padding: 0 1.25rem;
}
.headers[data-v-ff8668e8] {
 margin-bottom: 0.875rem;
}

.headersgrid[data-v-222fa60c],
.item[data-v-222fa60c] {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.875rem;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-222fa60c] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.cashoutgrid .headersgrid[data-v-222fa60c],
.cashoutgrid .item[data-v-222fa60c] {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.item[data-v-222fa60c] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  width: calc(100% - 1.875rem);
  margin: auto;
  margin-bottom: 0.5rem;
}
.dark .item[data-v-222fa60c] {
  background: #3b435e;
}
.item[data-v-222fa60c]:hover {
  border-color: #767bfa;
}
.item div[data-v-222fa60c],
.headersgrid div[data-v-222fa60c] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recentActivities .headersgrid[data-v-222fa60c],
.recentActivities .item[data-v-222fa60c] {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.25fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.items[data-v-222fa60c] {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  padding: 0.5rem 0;
}
.walletPage .box[data-v-222fa60c],
.cashoutgrid[data-v-222fa60c],
.bankAccounts[data-v-222fa60c],
.recentActivities[data-v-222fa60c] {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.activitiesImg[data-v-222fa60c] {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}
.activitiesImg img[data-v-222fa60c]:first-child {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.purchaseId[data-v-222fa60c] {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
  overflow: hidden;
}

.headersgrid[data-v-07359109],
.item[data-v-07359109] {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.875rem;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-07359109] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.cashoutgrid .headersgrid[data-v-07359109],
.cashoutgrid .item[data-v-07359109] {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.item[data-v-07359109] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  width: calc(100% - 1.875rem);
  margin: auto;
  margin-bottom: 0.5rem;
}
.dark .item[data-v-07359109] {
  background: #3b435e;
}
.item[data-v-07359109]:hover {
  border-color: #767bfa;
}
.item div[data-v-07359109],
.headersgrid div[data-v-07359109] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recentActivities .headersgrid[data-v-07359109],
.recentActivities .item[data-v-07359109] {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.25fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.items[data-v-07359109] {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  padding: 0.5rem 0;
}
.walletPage .box[data-v-07359109],
.cashoutgrid[data-v-07359109],
.bankAccounts[data-v-07359109],
.recentActivities[data-v-07359109] {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.activitiesImg[data-v-07359109] {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}
.activitiesImg img[data-v-07359109]:first-child {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.purchaseId[data-v-07359109] {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
  overflow: hidden;
}

.headersgrid[data-v-ae3ed878],
.item[data-v-ae3ed878] {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.875rem;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-ae3ed878] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.cashoutgrid .headersgrid[data-v-ae3ed878],
.cashoutgrid .item[data-v-ae3ed878] {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.item[data-v-ae3ed878] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  width: calc(100% - 1.875rem);
  margin: auto;
  margin-bottom: 0.5rem;
}
.dark .item[data-v-ae3ed878] {
  background: #3b435e;
}
.item[data-v-ae3ed878]:hover {
  border-color: #767bfa;
}
.item div[data-v-ae3ed878],
.headersgrid div[data-v-ae3ed878] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recentActivities .headersgrid[data-v-ae3ed878],
.recentActivities .item[data-v-ae3ed878] {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.25fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.items[data-v-ae3ed878] {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  padding: 0.5rem 0;
}
.walletPage .box[data-v-ae3ed878],
.cashoutgrid[data-v-ae3ed878],
.bankAccounts[data-v-ae3ed878],
.recentActivities[data-v-ae3ed878] {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.activitiesImg[data-v-ae3ed878] {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}
.activitiesImg img[data-v-ae3ed878]:first-child {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.purchaseId[data-v-ae3ed878] {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
  overflow: hidden;
}

.headersgrid[data-v-ff8668e8],
.item[data-v-ff8668e8] {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.875rem;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-ff8668e8] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.cashoutgrid .headersgrid[data-v-ff8668e8],
.cashoutgrid .item[data-v-ff8668e8] {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.item[data-v-ff8668e8] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  width: calc(100% - 1.875rem);
  margin: auto;
  margin-bottom: 0.5rem;
}
.dark .item[data-v-ff8668e8] {
  background: #3b435e;
}
.item[data-v-ff8668e8]:hover {
  border-color: #767bfa;
}
.item div[data-v-ff8668e8],
.headersgrid div[data-v-ff8668e8] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recentActivities .headersgrid[data-v-ff8668e8],
.recentActivities .item[data-v-ff8668e8] {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.25fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.items[data-v-ff8668e8] {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  padding: 0.5rem 0;
}
.walletPage .box[data-v-ff8668e8],
.cashoutgrid[data-v-ff8668e8],
.bankAccounts[data-v-ff8668e8],
.recentActivities[data-v-ff8668e8] {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.activitiesImg[data-v-ff8668e8] {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}
.activitiesImg img[data-v-ff8668e8]:first-child {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.purchaseId[data-v-ff8668e8] {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
  overflow: hidden;
}

.header[data-v-e02efd41] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-e02efd41] {
  cursor: pointer;
}
.input[data-v-e02efd41] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-e02efd41] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-e02efd41] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-e02efd41] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-e02efd41] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-e02efd41] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-e02efd41] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-e02efd41] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-e02efd41] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-e02efd41] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-e02efd41] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-e02efd41] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-e02efd41] {
  font-size: 1.125rem;
}
.readOnly[data-v-e02efd41] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-e02efd41] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-e02efd41] {
  grid-column: span 2;
}
.breakup[data-v-e02efd41] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-e02efd41] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-e02efd41] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-e02efd41] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-e02efd41] {
  justify-content: space-evenly;
}
.jc-sb[data-v-e02efd41] {
  justify-content: space-between;
}
.btns[data-v-e02efd41] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-e02efd41] {
  padding: 0 1.25rem;
}
.pt-10[data-v-e02efd41] {
  padding-top: 0.625rem;
}
.table-container[data-v-e02efd41] {
  margin: 0 0.625rem;
}
.span-2[data-v-e02efd41] {
  grid-column: 2/4;
}
.green[data-v-e02efd41] {
  color: #12c519;
}
.mb-20[data-v-e02efd41] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-e02efd41] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-e02efd41] {
  padding: 0 1.25rem;
}
.lg-header[data-v-e02efd41] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-e02efd41] {
  cursor: pointer;
}
.search-input-cm[data-v-e02efd41] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-e02efd41] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-e02efd41],
.sm-item-grid[data-v-e02efd41] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-e02efd41] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-e02efd41] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-e02efd41] {
  background: #3b435e;
}
.sm-items[data-v-e02efd41] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-e02efd41] {
  background: #3b435e;
}
.col-grid[data-v-e02efd41] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-e02efd41] {
  font-weight: 500;
}
.list-item[data-v-e02efd41] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-e02efd41] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-e02efd41] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-e02efd41] {
  background: #3b435e;
}
.btn-container[data-v-e02efd41] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-e02efd41] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-e02efd41] {
  background: #383f57;
}
.addTicketModal .header[data-v-e02efd41] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-e02efd41] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-e02efd41] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-e02efd41] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-e02efd41] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-e02efd41] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-e02efd41] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-e02efd41] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-e02efd41] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-e02efd41],
.add-ticket-details-grid .values-grid[data-v-e02efd41] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-e02efd41] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-e02efd41] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-e02efd41] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-e02efd41] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-e02efd41] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-e02efd41] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-e02efd41] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-e02efd41] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-e02efd41] {
  width: 100%;
}
.mapImg path.clickable[data-v-e02efd41] {
  cursor: pointer;
}

.popupbg[data-v-2b78f3ce] {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup[data-v-2b78f3ce] {
  background-color: #fff;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  padding: 1.25rem 1.5rem;
}
.dark .popup[data-v-2b78f3ce] {
  background-color: #323a52;
}
.text[data-v-2b78f3ce] {
  color: #767bfa;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.ok-btn[data-v-2b78f3ce] {
  background-color: #767bfa;
  color: #fff;
  border: none;
  border-radius: 0.625rem;
  padding: 0.425rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1.25rem;
  margin-bottom: 0.725rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}

.header[data-v-e8a32159] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;

  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}
.header img[data-v-e8a32159] {
  cursor: pointer;
}
.input[data-v-e8a32159] {
  max-width: 14.0625rem;
  margin: auto;
}
.image-side-input[data-v-e8a32159] {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.form-image-container[data-v-e8a32159] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.three-grid[data-v-e8a32159] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.one-grid[data-v-e8a32159] {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.label[data-v-e8a32159] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.625rem;
  padding-left: 1.375rem;
}
.note[data-v-e8a32159] {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}
.jus-end[data-v-e8a32159] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-start[data-v-e8a32159] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploaded[data-v-e8a32159] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}
.saved-alert[data-v-e8a32159] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.fees[data-v-e8a32159] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}
.total[data-v-e8a32159] {
  font-size: 1.125rem;
}
.readOnly[data-v-e8a32159] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.dark .readOnly[data-v-e8a32159] {
  color: #f6f7ff;
  background: #3b435e;
}
.span2[data-v-e8a32159] {
  grid-column: span 2;
}
.breakup[data-v-e8a32159] {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}
.breakup .fees[data-v-e8a32159] {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}
.gridImg[data-v-e8a32159] {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}
.jc-ce[data-v-e8a32159] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jc-se[data-v-e8a32159] {
  justify-content: space-evenly;
}
.jc-sb[data-v-e8a32159] {
  justify-content: space-between;
}
.btns[data-v-e8a32159] {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}
.px-20[data-v-e8a32159] {
  padding: 0 1.25rem;
}
.pt-10[data-v-e8a32159] {
  padding-top: 0.625rem;
}
.table-container[data-v-e8a32159] {
  margin: 0 0.625rem;
}
.span-2[data-v-e8a32159] {
  grid-column: 2/4;
}
.green[data-v-e8a32159] {
  color: #12c519;
}
.mb-20[data-v-e8a32159] {
  margin-bottom: 1.25rem !important;
}
.mb-10[data-v-e8a32159] {
  margin-bottom: 0.625rem !important;
}
.missing-container[data-v-e8a32159] {
  padding: 0 1.25rem;
}
.lg-header[data-v-e8a32159] {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.lg-header img[data-v-e8a32159] {
  cursor: pointer;
}
.search-input-cm[data-v-e8a32159] {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}
.sm-headers-grid[data-v-e8a32159] {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}
.sm-headers-grid[data-v-e8a32159],
.sm-item-grid[data-v-e8a32159] {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}
.sm-item-grid img[data-v-e8a32159] {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}
.sm-item-grid[data-v-e8a32159] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}
.dark .sm-headers-item[data-v-e8a32159] {
  background: #3b435e;
}
.sm-items[data-v-e8a32159] {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}
.dark .sm-item-grid[data-v-e8a32159] {
  background: #3b435e;
}
.col-grid[data-v-e8a32159] {
  display: flex;
  flex-direction: column;
}
.sm-bold[data-v-e8a32159] {
  font-weight: 500;
}
.list-item[data-v-e8a32159] {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.upload-ticket-container[data-v-e8a32159] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.upload-ticket-box[data-v-e8a32159] {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}
.dark .upload-ticket-box[data-v-e8a32159] {
  background: #3b435e;
}
.btn-container[data-v-e8a32159] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.upload-ticket-input[data-v-e8a32159] {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}
.dark .upload-ticket-input[data-v-e8a32159] {
  background: #383f57;
}
.addTicketModal .header[data-v-e8a32159] {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}
.addTicketModal .subText[data-v-e8a32159] {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #767475;
}
.addTicketModal .twoGrid[data-v-e8a32159] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0.625rem 0;
}
.addTicketModal .mapImg[data-v-e8a32159] {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}
.gap-0[data-v-e8a32159] {
  grid-gap: 0 !important;
  grid-gap: 0 !important;
  gap: 0 !important;
}
.addTicketModal .smHeader[data-v-e8a32159] {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}
.dark .addTicketModal .smHeader[data-v-e8a32159] {
  color: #f6f7ff;
}
.addTicketModal .smText[data-v-e8a32159] {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}
.dark .addTicketModal .smText[data-v-e8a32159] {
  color: #f6f7ff;
}
.add-ticket-details-grid .headersGrid[data-v-e8a32159],
.add-ticket-details-grid .values-grid[data-v-e8a32159] {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}
.border[data-v-e8a32159] {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 1.5rem 0;
}
.dark .border[data-v-e8a32159] {
  background-color: #5a5d74;
}
.add-ticket-details-grid .values-grid[data-v-e8a32159] {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}
.values-grid > div[data-v-e8a32159] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bodyGrid[data-v-e8a32159] {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
.blue-btn[data-v-e8a32159] {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}
.addTicketModal .label[data-v-e8a32159] {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}
.addTicketModal .label span[data-v-e8a32159] {
  width: 1rem;
  line-height: 12px;
  height: 1rem;
  font-size: 12px;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.addTicketModal .label span img[data-v-e8a32159] {
  width: 100%;
}
.mapImg path.clickable[data-v-e8a32159] {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

