.headersgrid[data-v-ff8668e8],
.item[data-v-ff8668e8] {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.875rem;
  grid-gap: 0.375rem;
}
.headersgrid[data-v-ff8668e8] {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.cashoutgrid .headersgrid[data-v-ff8668e8],
.cashoutgrid .item[data-v-ff8668e8] {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.item[data-v-ff8668e8] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  width: calc(100% - 1.875rem);
  margin: auto;
  margin-bottom: 0.5rem;
}
.dark .item[data-v-ff8668e8] {
  background: #3b435e;
}
.item[data-v-ff8668e8]:hover {
  border-color: #767bfa;
}
.item div[data-v-ff8668e8],
.headersgrid div[data-v-ff8668e8] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recentActivities .headersgrid[data-v-ff8668e8],
.recentActivities .item[data-v-ff8668e8] {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.25fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.375rem;
}
.items[data-v-ff8668e8] {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
  padding: 0.5rem 0;
}
.walletPage .box[data-v-ff8668e8],
.cashoutgrid[data-v-ff8668e8],
.bankAccounts[data-v-ff8668e8],
.recentActivities[data-v-ff8668e8] {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.activitiesImg[data-v-ff8668e8] {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}
.activitiesImg img[data-v-ff8668e8]:first-child {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.purchaseId[data-v-ff8668e8] {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
  overflow: hidden;
}
