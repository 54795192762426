@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

body {
  overflow-x: auto;
  font-family: "Poppins", sans-serif;
}

.App {
  background-color: #f6f7ff;
  color: #323a52;
  overflow-x: auto;
}

.App.dark {
  background-color: #323a52;
  color: #f6f7ff;
}

.main-container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: minmax(12.875rem, -webkit-min-content) 1fr;
  grid-template-columns: minmax(12.875rem, min-content) 1fr;
}

a {
  text-decoration: none;
  font-family: inherit;
}

.page {
  min-width: 1200px;
  padding-right: 1.0625rem;
}

.px {
  padding-left: 3.125rem !important;
  padding-right: 0.9375rem !important;
}

.px-24 {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.pl {
  padding-left: 3.125rem !important;
}

.pr {
  padding-right: 0.9375rem !important;
}

.jc-fe {
  justify-content: flex-end;
}

.pr-24 {
  padding-right: 1.5rem !important;
}

input {
  font-family: inherit;
  outline: none;
}
.clickable {
  cursor: pointer;
}

.css-3cjm13-MuiFormControl-root {
  margin: 0rem !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiSelect-select {
  font-family: "Poppins" !important;
  padding: 0.875rem 1.375rem !important;
}

.smselect .MuiSelect-select {
  padding: 0.1875rem 0.75rem !important;
  padding-right: 0 !important;
}

.modal .MuiBackdrop-root.css-sox5kk-MuiBackdrop-root {
  background: rgba(67, 53, 97, 0.22);
  -webkit-backdrop-filter: blur(0.375rem);
          backdrop-filter: blur(0.375rem);
}

.dark.modal .MuiBackdrop-root.css-sox5kk-MuiBackdrop-root {
  background: rgba(24, 22, 37, 0.35);
}

.mt-10 {
  margin-top: 0.625rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.ml-10 {
  margin-left: 0.625rem;
}

.mr-14 {
  margin-right: 0.875rem;
  max-width: 9.0625rem;
  width: 100%;
}

.center {
  text-align: center;
  justify-content: center;
}

.MuiSelect-select[aria-expanded="true"] > img {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

/* width */
::-webkit-scrollbar {
  width: 0.1875rem;
  border-radius: 0.1875rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #abafd1;
  cursor: pointer;
  border-radius: 0.1875rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #767bfa;
  cursor: pointer;
  border-radius: 0.1875rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4d53fd;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.add-btns {
  display: flex;
  margin-bottom: 0.625rem;
}

.flex {
  display: flex;
}

.jc-sp {
  justify-content: space-between;
}

.jc-ce {
  justify-content: center;
}

.jc-e {
  justify-content: end;
}

.al-ce {
  align-items: center;
}

.col {
  flex-direction: column;
}

.ml-5 {
  margin-left: 0.3125rem;
}

.red {
  color: #fa7676 !important;
}

.green {
  color: #12c519;
}

.purple {
  color: #767bfa;
}

.orange {
  color: #d0a879;
}

.blue {
  color: #78aad8;
}

.MuiOutlinedInput-notchedOutline,
.css-igs3ac {
  border: 0rem !important
  ;
}

.Mui-focusVisible {
  background-color: transparent !important;
}

.Mui-selected {
  background-color: rgba(118, 123, 250, 0.2) !important;
}

.sneakers {
  background-color: #7983d0; /* blue */
}

.streetwear {
  background-color: #79c1d0; /* green */
}

.collectibles {
  background-color: #d07979; /* brown */
}

.arts {
  background-color: #d079c8; /* pink */
}

.electronics {
  background-color: #d0a879; /* orange */
}

.watches {
  background-color: #aad079; /* lightgreen */
}

@media (max-width: 1560px) {
  :root {
    font-size: 12.5px;
  }
}

@media (max-width: 1750px) {
  :root {
    font-size: 13.5px;
  }
}

.css-a2af34-MuiTable-root {
  min-width: none !important;
  width: 100%;
}

.recharts-wrapper {
  margin-left: -0.75rem;
}

.price-graph .recharts-wrapper {
  margin-left: -1.25rem;
}

.swiper-button-next {
  background-image: url("./assets/Right.svg") !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: translateX(0.375rem);
  -webkit-transform: translateX(0.375rem);
  -moz-transform: translateX(0.375rem);
  -ms-transform: translateX(0.375rem);
  -o-transform: translateX(0.375rem);
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url("./assets/Left.svg") !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: translateX(-0.375rem);
  -webkit-transform: translateX(-0.375rem);
  -moz-transform: translateX(-0.375rem);
  -ms-transform: translateX(-0.375rem);
  -o-transform: translateX(-0.375rem);
}

.swiper-button-prev::after {
  display: none;
}

.qrprint {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrprint1 {
  position: absolute;
  top: 50%;
  width: 350px;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.qrprint2 {
  width: 350px;
  margin: auto;
}

.print2 {
  display: block;
  height: -webkit-max-content;
  height: max-content;
  margin: auto;
  align-items: center;
}

.print3 {
  display: block;
  height: -webkit-max-content;
  height: max-content;
  margin: auto;
  align-items: center;
}

.cap {
  text-transform: capitalize;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.al-fe {
  align-items: flex-end !important;
}

.lockedimg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
  object-fit: cover;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #323a52 !important;
}

.dark
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #5a5d74 !important;
}

.dark .menuitem {
  color: #f6f7ff !important;
}

.dark.modal .btn {
  background-color: #3b435e !important;
}

.css-2lkrky-MuiPaper-root-MuiTableContainer-root {
  overflow-x: visible !important;
}

.css-11c9tec-MuiTableCell-root {
  z-index: 0 !important;
}
