.headersection[data-v-e3e75ef8] {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}
.headersection[data-v-e3e75ef8]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url("../../assets/dash.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-e3e75ef8]::before {
  background-image: url("../../assets/dashdark.png");
}
.green[data-v-e3e75ef8] {
  color: #12c519;
}
.black[data-v-e3e75ef8] {
  margin-right: 0.3125rem;
  color: #323a52 !important;
}
.dark .black[data-v-e3e75ef8] {
  color: #f6f7ff !important;
}
.itemssold[data-v-e3e75ef8] {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.35rem;
  margin-left: 0.625rem;
}
.al-fe[data-v-e3e75ef8] {
  align-items: flex-end;
}
.px-20[data-v-e3e75ef8] {
  padding: 0 1.25rem;
}
.headers[data-v-e3e75ef8] {
  margin-bottom: 0.875rem;
}
.bold[data-v-e3e75ef8] {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}
.transaction-history[data-v-e3e75ef8] {
  grid-row: span 2;
}
.type[data-v-e3e75ef8]:not(:last-child) {
  margin-right: 1.5625rem;
}
.type[data-v-e3e75ef8] {
  color: #abafd1;
  cursor: pointer;
}
.type.active[data-v-e3e75ef8] {
  color: #323a52;
}
.dark .type[data-v-e3e75ef8] {
  color: #5a5d74;
}
.dark .type.active[data-v-e3e75ef8] {
  color: #f6f7ff;
}
