.headersection[data-v-4ea0834d] {
  position: relative;
  padding: 0 1rem;
}
.headersection[data-v-4ea0834d]::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-image: url("../../assets/dash.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.dark .headersection[data-v-4ea0834d]::before {
  background-image: url("../../assets/dashdark.png");
}
.smtext[data-v-4ea0834d] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}
.px-25[data-v-4ea0834d] {
  padding-left: 1.25rem;
  margin-top: 1.5625rem;
}
.label[data-v-4ea0834d] {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.75rem;
}
.wrap[data-v-4ea0834d] {
  grid-gap: 1.5625rem 3rem;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(4, 14.0625rem);
}
.select-container[data-v-4ea0834d] {
  max-width: 14.0625rem;
  width: 100%;
  flex-shrink: 0;
}
.twogrid[data-v-4ea0834d] {
  display: grid;
  grid-template-columns: 1fr;
}
.two-grid[data-v-4ea0834d] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}
.end[data-v-4ea0834d] {
  justify-content: flex-end;
}
@media (max-width: 1700px) {
.wrap[data-v-4ea0834d] {
    grid-gap: 1.5rem 2.125rem;
}
}
.btncontainer[data-v-4ea0834d] {
  grid-column: span 4;
  margin-top: 1.875rem;
  justify-content: flex-end;
}
.w-100[data-v-4ea0834d] {
  width: 100%;
}
.abs[data-v-4ea0834d] {
  position: absolute;
  right: 2rem;
  top: -3rem;
}
.mt-custom[data-v-4ea0834d] {
  margin-top: 7rem;
}
.rel[data-v-4ea0834d] {
  position: relative;
}
.cp[data-v-4ea0834d] {
  cursor: pointer;
}
.visible[data-v-4ea0834d] {
  -webkit-transform-origin: center;
          transform-origin: center;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.px-15[data-v-4ea0834d] {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgb(0 0 0 / 15%);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
  padding-right: 1rem;
}
.settings-container[data-v-4ea0834d] {
  max-height: 85vh;
  overflow-y: auto;
  padding-bottom: 1rem;
}
.dark .px-15[data-v-4ea0834d] {
  background: #353c53;
}
.pr-15[data-v-4ea0834d] {
  padding-right: 1.5rem;
}
.pad-5[data-v-4ea0834d] {
  margin-right: 15px;
}
